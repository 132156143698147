import React, { useState } from "react";
import ResponsivePagination from "react-responsive-pagination";
// import 'react-responsive-pagination/themes/classic.css';
export const PaginationTab = ({
  totalPage,
  handlePageSchema,
  pageSchema,
  data,
}) => {
  // const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className=" tw-text-center pt-4">
      <ResponsivePagination
        current={data?.current_page}
        total={data?.last_page}
        maxWidth={500}
        onPageChange={(value) => handlePageSchema("currentPage", value)}
      />
    </div>
  );
};
