import React, { useLayoutEffect } from "react";
import { RecipeCard, SkeltonCard } from "../../components/Cards";
import { useQuery } from "@tanstack/react-query";
import { getSmoothies } from "../../services/Smoothie";
import Loader from "../../components/common/Loader";
import HeroBanner from "../../components/common/HeroBanner";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function DiscoverRecipes() {
  const { pathname } = useLocation();

  const {
    isLoading: smoothieLoading,
    error: smoothieError,
    data: smoothieData,
  } = useQuery({
    queryKey: ["smoothieListing"],
    queryFn: getSmoothies,
  });
  const smoothies = smoothieData?.data?.smoothies || [];
  const categories = smoothieData?.data?.categories;

  // let page = searchParams.get("page");
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
      </Helmet>
      {smoothieLoading && <Loader />}
      {/* <DiscoverHero /> */}
      <HeroBanner data={{ title: "Discover Smoothies" }} />
      <div className="flx-hero-about !tw-h-auto max-md:!tw-px-4 tw-text-center">
        <Link to="/wishlist" className="btn btn-primary btn-solid-success">
          Wishlist
        </Link>
        &nbsp;&nbsp;&nbsp;
        <Link
          to="/smoothiemixer"
          className="btn btn-primary btn-outline-success"
        >
          Blend smoothie
        </Link>
      </div>
      {/* <!------Our boxes Listing start------> */}
      <section id="flx-discover-recipes" className="!tw-pt-10 ">
        <div className="container">
          {(smoothieLoading ||
            smoothies?.filter((d) => d.created_by == 1)?.length > 0) && (
            <h3
              className="pb-5 pt-3"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              your mixes
            </h3>
          )}
          <div className="row">
            {smoothieLoading || smoothies.length == 0
              ? Array.from(Array(8))?.map((box, index) => {
                  return (
                    <div
                      key={index}
                      className="col-12 col-md-4 mb-4"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <SkeltonCard />
                    </div>
                  );
                })
              : //   data
                // ?.filter((d) => d.smoothie.created_by == 1)
                smoothies?.filter((d) => d.created_by == 1)?.length > 0 && (
                  <div className=" tw-pb-20">
                    {smoothies
                      ?.filter((d) => d.created_by == 1)
                      ?.map((smooth, index) => {
                        return (
                          <div key={index} className="col-12 col-md-4 p-3">
                            <div data-aos="fade-up" data-aos-duration="1000">
                              <RecipeCard
                                isButton={true}
                                data={smooth}
                                actionTitle="Mehr anzeigen"
                                action={`/recipedetail/${smooth?.unique_id}`}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
          </div>
          <h3
            className="pt-0 pt-md-3 "
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            From the Individual Cookbook
          </h3>
          <div className="row pt-4">
            {smoothieLoading || smoothies.length == 0
              ? Array.from(Array(8))?.map((box, index) => {
                  return (
                    <div
                      key={index}
                      className="col-12 col-md-4 mb-4"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <SkeltonCard />
                    </div>
                  );
                })
              : smoothies
                  ?.filter((d) => d.created_by != 1)
                  ?.map((smooth, index) => {
                    return (
                      <div key={index} className="col-12 col-md-4 p-3">
                        <div data-aos="fade-up" data-aos-duration="1000">
                          <RecipeCard
                            isButton={true}
                            data={smooth}
                            actionTitle="Mehr anzeigen"
                            action={`/recipedetail/${smooth?.unique_id}`}
                          />
                        </div>
                      </div>
                    );
                  })}
          </div>
        </div>
      </section>
      {/* <!------Our boxes Listing end------> */}
    </div>
  );
}
