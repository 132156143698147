import { GET } from "./Adapter/base";

export const getWebSettings = () => {
  return GET(`setting`);
};

export const getCommonImg = () => {
  return GET(`custom_image`);
};

export const getCookies = () => {
  return GET(`cookies`);
};
