import React, { useLayoutEffect } from "react";
import { BoxCard, SkeltonCard } from "../../components/Cards";
import Loader from "../../components/common/Loader";
import { useQuery } from "@tanstack/react-query";
import { getBoxes } from "../../services/SmoothieBox";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

export default function Boxes() {
  const { pathname } = useLocation();
  const isAuthenticated = useSelector((state) => state.account.isAuthenticated);

  const {
    isLoading: boxLoading,
    error: boxError,
    data: boxData,
  } = useQuery({
    queryKey: ["boxListing", isAuthenticated],
    queryFn: getBoxes,
  });
  const boxes = boxData?.data?.data || [];
  // let page = searchParams.get("page");
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const navigate = useNavigate();

  // const handlePageClick = (e) => {
  //   navigate(`/rightrecipes?page=${e.selected + 1}`);
  // };

  return (
    <div>
      {boxLoading && <Loader />}
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
        <title> {`Indivit | Finde deine passende Smoothiebox`} </title>
        <meta
          name="description"
          content={`Unsere Smoothieboxen enthalten nicht nur köstliche Smoothies,
              sondern sie sind auch ideal an deinen Lifestyle angepasst. Egal,
              ob Du deine Smoothies gerne zum Sport, zur Arbeit oder als
              Mahlzeitenersatz genießen möchtest – wir haben die passende Box
              dafür zusammengestellt.`}
        />
      </Helmet>
      {/* <!-- hero banner start--> */}
      <section
        id="flx-hero-section"
        className="max-xl:after:!tw-bg-none max-lg:before:!tw-bg-none"
      >
        <div className="container ">
          <div className="flx-hero-about md:!tw-pt-7 lg:!tw-pt-20 sm:!tw-pt-5 !tw-pt-3 !tw-h-auto">
            <h1 className="text-center pb-2">
              Finde deine passende Smoothiebox
            </h1>
          </div>
        </div>
      </section>

      <div className="flx-hero-about !tw-h-auto max-md:tw-px-4">
        <p className="text-center pb-2">
          Unsere Smoothieboxen enthalten nicht nur köstliche Smoothies, sondern
          sie sind auch ideal an deinen Lifestyle angepasst. Egal, ob Du deine
          Smoothies gerne zum Sport, zur Arbeit oder als Mahlzeitenersatz
          genießen möchtest – wir haben die passende Box dafür zusammengestellt.
        </p>
        <p className="text-center pb-2">
          Du kannst jede Box natürlich ganz individuell an deine Vorlieben
          anpassen – öffne sie einfach mit dem Box Customizer.
        </p>
      </div>
      {/* <!-- hero banner end--> */}

      {/* <!-- hero banner end--> */}
      <section id="flx-nav-pils" className="!tw-pt-10">
        <div className="container">
          <div className="tab-content" id="pills-tabContent">
            {/* <!-- All tabs setting --> */}
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              tabIndex="0"
            >
              {/* <!------Our boxes start------> */}
              <div className="row">
                {boxLoading || boxes.length == 0
                  ? Array.from(Array(8))?.map((box, index) => {
                      return (
                        <div
                          key={index}
                          className="col-12 col-md-4  hsn-box-bg"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                        >
                          <SkeltonCard />
                        </div>
                      );
                    })
                  : boxes?.map((box, index) => {
                      return (
                        <div key={index} className="col-12 col-md-4  p-3">
                          <div
                            className="  hsn-box-bg"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                          >
                            <BoxCard data={box} />
                          </div>
                        </div>
                      );
                    })}
              </div>
              {/* <!------Our boxes end------> */}
            </div>
            {/* <!-- All tabs setting end --> */}
            {/* <!-- fruit tabs setting --> */}
            {/* {categories?.map((categ, index) => {
              return (
                <div
                  className="tab-pane fade"
                  id={`pills-smoothies-${categ.id}`}
                  role="tabpanel"
                  //   aria-labelledby="pills-fruit-tab"
                  tabIndex={categ.id}
                >
                  <div className="row g-4">
                    {smoothies
                      ?.filter((obj) =>
                        obj.smoothie_categories.some(
                          (categSub) => categSub.category_id == categ.id
                        )
                      )
                      .map((smooth, index) => {
                        return (
                          <div
                            className="col-12 col-md-4"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                          >
                            <RecipeCard
                              data={smooth}
                              isButton={true}
                              actionTitle="Mehr anzeigen"
                              action={`/recipedetail/${smooth?.id}`}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })} */}

            {/* <!--Caffeine tabs setting end --> */}
          </div>
        </div>
        {/* <div className="d-flex justify-content-center mt-5">
          <ReactPaginate
            breakLabel="..."
            nextLabel={<i className="fa-solid fa-greater-than"></i>}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={smoothies?.last_page}
            previousLabel={<i className="fa-solid fa-less-than"></i>}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            activeClassName="active"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
          />
        </div> */}
      </section>
    </div>
  );
}
