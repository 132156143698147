import React from "react";
import SustainabilityDetails from "./SustainabilityDetails";
import { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import HeroBanner from "../../components/common/HeroBanner";
import { useLocation } from "react-router-dom";

export default function Sustainability() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
        <title> {`Indivit | Nachhaltigkeit liegt uns am Herzen`} </title>
        <meta
          name="description"
          content={`Vieles von dem, was wir täglich umsetzen, möchten wir auch in den nächsten Jahren weiterführen. Daher liegt uns ein nachhaltiges Handeln sehr nahe. Das bedeutet für uns ganz konkret, wir positionieren uns für einen umfassenden Umweltschutz, ein vollständiges Recycling, einen sinnvollen Ressourceneinsatz und minimale Verschwendung.`}
        />
      </Helmet>

      <HeroBanner
        data={{
          title: "Nachhaltigkeit liegt uns am Herzen",
          description:
            "Vieles von dem, was wir täglich umsetzen, möchten wir auch in den nächsten Jahren weiterführen. Daher liegt uns ein nachhaltiges Handeln sehr nahe. Das bedeutet für uns ganz konkret, wir positionieren uns für einen umfassenden Umweltschutz, ein vollständiges Recycling, einen sinnvollen Ressourceneinsatz und minimale Verschwendung.",
        }}
      />
      <SustainabilityDetails />
    </div>
  );
}
