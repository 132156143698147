import React, { useEffect, useLayoutEffect, useState } from "react";
import { IngredientCard, RecipeCard } from "../../components/Cards";
import { getIngredientById } from "../../services/Ingredients";
import { Link, useLocation, useParams } from "react-router-dom";
import Loader from "../../components/common/Loader";
import AllNutrientsPopup from "./AllNutrientsPopup";
import { useDispatch, useSelector } from "react-redux";
import TasteSection from "./TasteSection";
import NutrientsSection from "./NutrientsSection";
import IngredientBasicInfoSection from "./IngredientBasicInfoSection";
import { TextSkelton } from "../../components/common/Common";
import { useQuery } from "@tanstack/react-query";
import { getSmoothies } from "../../services/Smoothie";
import { Helmet } from "react-helmet";
export default function IngredientDetail() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [relevantData, setRelevantData] = useState([]);
  const [relativeIngredients, setRelativeIngredients] = useState([]);
  const [relativeSmoothies, setRelativeSmoothies] = useState([]);

  let params = useParams();
  let { state, pathname } = useLocation();
  // console.log(
  //   "use Location from Ingredient Detail ",
  //   state?.ingred_suggestions
  // );
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);
  useEffect(() => {
    setLoading(true);
    const fetchData = async (id) => {
      await getIngredientById(id).then((res) => {
        setData(res?.data?.data?.ingredients);
        // console.log("Res from Relative -> ", res);
        let tempSuggest =
          state?.ingred_suggestions?.filter((d) => d.id != params?.id) || [];
        setRelativeIngredients([
          ...tempSuggest,
          ...res?.data?.data?.relative_ingredients,
        ]);
        setRelativeSmoothies(res?.data?.data?.relative_smoothies);
        setLoading(false);
      });
    };
    if (params?.id) {
      fetchData(params?.id);
    }
  }, [params?.id]);
  const {
    isLoading: smoothieLoading,
    error: smoothieError,
    data: smoothieData,
  } = useQuery({
    queryKey: ["smoothieListing"],
    queryFn: getSmoothies,
  });
  const smoothiesListing = smoothieData?.data?.smoothies;

  const dispatch = useDispatch();

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
      </Helmet>
      {data && (
        <Helmet>
          {data?.name && (
            <title> {`Indivit | Ingredient | ${data?.name}`} </title>
          )}
          {data?.key_factor_headline && (
            <meta name="description" content={data?.key_factor_headline} />
          )}
        </Helmet>
      )}
      {data && <AllNutrientsPopup data={data?.nutrients} />}
      {loading && <Loader />}
      <section id="flx-hero-rdetailed">
        <div className="container">
          <div className="row">
            <nav aria-label="breadcrumb" className="px-0">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/ingredients">Ingredients</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {loading ? <TextSkelton /> : data?.name}
                </li>
              </ol>
            </nav>
          </div>
          <div className="pt-0 pt-md-5">
            <IngredientBasicInfoSection data={data} loading={loading} />
          </div>
        </div>
      </section>

      {/* <!-- progressbar section --> */}
      <section id="flx-idetailed-probr">
        <div className="container">
          <div className="row d-flex g-5">
            <div className="col-12  col-md-6 ">
              <div className="row">
                <div className="col-6 col-md-9">
                  <span className="fs-3 fw-bold">Was steckt drin?</span>
                  <span className="fw-normal fs-6"> (pro 250ml) </span>
                </div>
                <div className="col-6 col-md-3">
                  <button
                    type="button"
                    className="btn btn-link btn-solid-success-color ps-0"
                    data-bs-toggle="modal"
                    data-bs-target="#nutrientsModal"
                    data-bs-whatever="@getbootstrap"
                  >
                    Durchschnittliche Nährwerte
                  </button>
                </div>
              </div>
              <NutrientsSection data={data} ingVal={true} />
            </div>
            <div className="col-12  col-md-6 ">
              <h3>Wie schmeckt’s?</h3>
              <TasteSection data={data} suggest="no" basiColor={"green"} />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- progressbar section --> */}

      {/* <!-- simple text --> */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>
                {data?.name} stellt sich selbst vor{" "}
                {data?.external_link && (
                  <a
                    className="p-2 fs-4"
                    href={data?.external_link}
                    target="_blank"
                    rel="noopener"
                    title="External Refernce"
                  >
                    <i class="fas fa-link text-theme-success"></i>
                  </a>
                )}{" "}
              </h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.ingredient_description,
                }}
              ></div>
              {/* <p>{data?.ingredient_category?.detail}</p> */}
            </div>
          </div>
        </div>
      </section>
      {/* Simple Text End */}

      {/* Other Ingredients Start */}

      <section id="flx-other-ingredients">
        <div className="container">
          <div className="mb-5">
            {relativeSmoothies?.length > 0 && (
              <h3 className=" " data-aos="fade-up" data-aos-duration="1000">
                Contained in...
              </h3>
            )}
            <div className="row pt-4">
              {relativeSmoothies?.slice(0, 6)?.map((smooth, index) => {
                return (
                  <div
                    className="col-12 col-md-4"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <RecipeCard
                      isButton={true}
                      data={smooth?.smoothie}
                      actionTitle="Mehr anzeigen"
                      action={`/recipedetail/${smooth?.smoothie_id}`}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          {/* <!-- Ingredients --> */}
          <div className="">
            <h3>Weitere Zutaten...</h3>
            <div className="row pt-4 g-4">
              {relativeIngredients?.map((ingred, index) => {
                return (
                  <div
                    className="col-12 col-md-4"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <IngredientCard
                      data={ingred}
                      ingred_suggestions={relativeIngredients}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      {/* Other Ingredients Start */}
    </div>
  );
}
