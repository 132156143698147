import React from "react";

export function TextSkelton() {
  return (
    <span class=" placeholder-glow d-inline-flex py-1 ">
      <span class="placeholder w-200px"></span>
    </span>
  );
}
export function ScrollToDiv(divId, duration = 1000) {
  const element = document.getElementById(divId);
  if (!element) {
    console.warn(`Element with ID ${divId} not found.`);
    return;
  }
  element.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
}

export function ShowMoreBtn({ show, setShow, list }) {
  return (
    <>
      {list?.length > 3 && (
        <div className="tw-text-center tw-block md:tw-hidden tw-pb-4">
          <button
            onClick={() => setShow((prev) => !prev)}
            className="tw-bg-theme tw-font-bold tw-rounded-3xl tw-border-0 tw-text-white tw-p-x-2 tw-py-1 tw-text-xs"
          >
            {
              show
                ? "Weniger Anzeigen" // See Less
                : list?.length > 3 && list?.length - 3 + " Weitere Zutaten" // More Ingredients
            }
          </button>
        </div>
      )}
    </>
  );
}

// Example usage:
// scrollToDiv("myDiv", 2000); // Scrolls to the element with ID "myDiv" over 2 seconds
