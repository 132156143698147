import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchWishlist } from "../../redux/wishlist";
import { addWishlistBox } from "../../services/Wishlist";
import { toast } from "react-toastify";
import loader from "../../assets/icon/loader.gif";
import { Heart } from "../../assets/icon/svgIcons";
import { IsWishlist } from "../../components/common/utils";
import { Link } from "react-router-dom";
import { baseURL } from "../../services/Adapter/customAxios";
import productImg from "../../assets/img/ourbox.png";
import useAddWishlist from "../../hooks/useAddWishlist";
import { useState, useEffect } from "react";
import ModalContainer from "../../components/Modal/ModalContainer";
import ConfirmWishModal from "../../components/Modal/ConfirmWishModal";

export function BoxCard({ data }) {
  const wishlist = useSelector((state) => state?.wishlist);
  const { isLoading, addWishlist, isDone } = useAddWishlist(addWishlistBox);
  const [modalVisible, setModalVisible] = useState(false);
  const commonImg = useSelector((state) => state.settings?.boxImg);

  const handleWishlistBox = (box_id) => {
    IsWishlist(1, data?.box_id, wishlist)
      ? setModalVisible(true)
      : addWishlist({ box_id: box_id });
  };

  // const filterData = IsWishlist()
  const imageName = (data) => {
    return data?.box?.smoothie_image[0]?.images;
  };
  useEffect(() => {
    if (isDone) {
      setModalVisible(false);
    }
  }, [isDone]);
  return (
    <>
      <ModalContainer
        isOpen={modalVisible}
        closeModal={() => setModalVisible(false)}
      >
        <ConfirmWishModal
          setModalVisible={setModalVisible}
          fun={() => addWishlist({ box_id: data?.box?.unique_id })}
          isLoading={isLoading}
          innerHtml="Du entfernst das Element gerade von deinem Merkzettel"
          okLabel="Klingt gut"
        />
      </ModalContainer>
      <div className="box-card">
        <button
          type="button"
          className="btn btn-light box-wish shadow"
          onClick={() => handleWishlistBox(data?.box?.unique_id)}
        >
          {/* Type  0 => Recipe, 1 => Box , 2=> Ingredient */}
          {isLoading ? (
            <img width="50px" src={loader} className="img-fluid" />
          ) : (
            <Heart filled={IsWishlist(1, data?.box_id, wishlist)} />
          )}
        </button>
        <div className="text-center">
          <Link to={`/b/${data?.box?.unique_id}`}>
            <img
              src={
                imageName(data)
                  ? baseURL + "smoothie_box/" + imageName(data)
                  : commonImg
              }
              className="img-fluid"
            />
          </Link>

          <h4 className="flx-hide-title">{data?.box?.name}</h4>
          <p className="p5 flx-hide-paragh">
            {data?.box?.smoothie_box_descriptions[0]?.short_detail}
          </p>
        </div>
        <div className="flx-overlay-container">
          <Link
            to={`/b/${data?.box?.unique_id}`}
            className="btn btn-secondary hsn-box-btn"
          >
            Offene Box
          </Link>
        </div>
      </div>
    </>
  );
}
