import React, { useLayoutEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { getBlogById } from "../../services/BlogPages";
import { useQuery } from "@tanstack/react-query";
import { baseURL } from "../../services/Adapter/customAxios";
import Loader from "../../components/common/Loader";
import leftArrow from "../../assets/object/left.png";
import rightArrow from "../../assets/object/right.png";
import { Helmet } from "react-helmet";
import ShareButtons, {
  FABComponent,
} from "../../components/common/ShareButtons";

export default function BlogDetail() {
  const { pathname } = useLocation();
  const { slug } = useParams();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);
  let id = slug?.split("_")[slug?.split("_")?.length - 1];
  const {
    isLoading: blogLoading,
    error: blogError,
    data,
  } = useQuery({
    queryKey: ["blogById", slug],
    queryFn: () => getBlogById(id || 2),
  });
  let blogData = data?.data?.data;
  let next_title_slug = blogData?.next?.title?.split(" ")?.join("-");
  let nextSlug = next_title_slug?.concat(`_${blogData?.next?.id}`);

  let prev_title_slug = blogData?.previous?.title?.split(" ")?.join("-");
  let prevSlug = prev_title_slug?.concat(`_${blogData?.previous?.id}`);
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
        <title> {`Indivit | ${blogData?.current?.title}`} </title>
        <meta name="description" content={blogData?.current?.short_text} />
      </Helmet>
      {blogLoading && <Loader />}
      <div className="tw-mx-auto tw-max-w-[1440px] ">
        <div className="tw-flex tw-flex-col  tw-mx-auto tw-overflow-hidden tw-rounded">
          <img
            src={
              baseURL + "blogs/" + blogData?.current?.image ||
              "https://source.unsplash.com/random/480x360"
            }
            alt=""
            className="tw-w-full tw-h-auto tw-max-w-6xl tw-mx-auto lg:tw-rounded-xl lg:tw-mt-36 md:tw-mt-32 tw-mt-28"
          />
          <div className="tw-pb-12 tw-mx-auto tw-space-y-6 sm:tw-px-10 sm:tw-mx-12 md:tw-px-16 tw-px-5 ">
            <div className="tw-max-w-6xl tw-mx-auto lg:tw-px-6">
              <div className="tw-inline-block lg:tw-text-3xl md:tw-text-2xl sm:tw-text-xl lg:-tw-mt-10 -tw-mt-10 tw-font-bold tw-bg-white tw-w-full tw-shadow-lg tw-p-4 tw-rounded-lg tw-text-center sm:tw-truncate">
                {blogData?.current?.title}
              </div>
            </div>
            {/* <div className=" tw-text-end">
              <ShareButtons />
            </div> */}
            <FABComponent />
            {/* <div className="tw-space-y-2"> </div> */}
            {/* <p className="text-xs dark:text-gray-600">
                By
                <a
                  rel="noopener noreferrer"
                  href="#"
                  className="text-xs hover:underline"
                >
                  Leroy Jenkins
                </a>
              </p> */}

            <div className="  lg:tw-rounded-md  sm:tw-py-10 md:tw-py-16 tw-py-5 ">
              <p
                dangerouslySetInnerHTML={{ __html: blogData?.current?.body }}
              ></p>
            </div>
          </div>
        </div>

        <div className="tw-flex tw-justify-between md:tw-px-16 sm:tw-px-10 tw-px-5 ">
          <div className="">
            {" "}
            <Link
              to={`/blogs/${prevSlug}`}
              className="tw-flex tw-items-center tw-gap-4 tw-decoration-transparent tw-font-bold tw-text-black hover:tw-text-theme"
            >
              {blogData?.previous && (
                <img
                  height="auto"
                  width="31px"
                  className="img-fluid "
                  src={leftArrow}
                />
              )}
              <span className="sm:tw-block tw-hidden">
                {blogData?.previous?.title}
              </span>
              <span className="tw-block sm:tw-hidden">Previous Blog</span>
            </Link>
          </div>
          <div className="">
            <Link
              to={`/blogs/${nextSlug}`}
              className="tw-flex tw-items-center tw-gap-4 tw-decoration-transparent tw-text-end tw-font-bold tw-text-black hover:tw-text-theme"
            >
              <span className="sm:tw-block tw-hidden">
                {blogData?.next?.title}
              </span>
              <span className="tw-block sm:tw-hidden">Next Blog</span>
              {blogData?.next && (
                <img
                  height="auto"
                  width="31px"
                  className="img-fluid "
                  src={rightArrow}
                />
              )}{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
