import React, { useEffect, useState, useLayoutEffect } from "react";
import { RecipeCard, SkeltonCard } from "../../components/Cards";
import Loader from "../../components/common/Loader";
import { getSmoothies } from "../../services/Smoothie";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
export default function RightRecipes() {
  const { pathname } = useLocation();

  // const [smoothies, setSmoothies] = useState([]);
  const isAuthenticated = useSelector((state) => state.account.isAuthenticated);

  const {
    isLoading: smoothieLoading,
    error: smoothieError,
    data: smoothieData,
  } = useQuery({
    queryKey: ["smoothieListing", isAuthenticated],
    queryFn: getSmoothies,
  });
  // let smoothies = [] ;
  const smoothies = smoothieData?.data?.smoothies || [];
  // useEffect(() => {

  //     let  tempSmoothies = smoothieData?.data?.smoothies?.map(smo=>{
  //    //   debugger
  //     return  smo?.smoothie_ingredient?.map(ing=>{
  //       //  let {ingredient} = ing
  //        return ({...ing,
  //           ...ing?.ingredient})
  //         })
  //     })
  //     console.log("temp SMothies ", tempSmoothies);
  //   setSmoothies(tempSmoothies)
  // }, [smoothieData]);
  const categories = smoothieData?.data?.categories;

  // let page = searchParams.get("page");
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const navigate = useNavigate();

  // const handlePageClick = (e) => {
  //   navigate(`/rightrecipes?page=${e.selected + 1}`);
  // };

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
        <title> {`Indivit | Finde dein Lieblingsrezept`} </title>
        <meta
          name="description"
          content={`Unsere Smoothie-Rezepte sind nicht nur köstlich, sondern auch eine
          fantastische Möglichkeit, eine schnelle und gesunde Mahlzeit zu
          sich zu nehmen. Egal, ob Du ein Frühstück für unterwegs, einen
          Snack nach dem Training oder einfach nur eine erfrischende
          Leckerei suchst, es gibt ein Smoothie-Rezept für jede Gelegenheit.
          Du kannst jedes Rezept natürlich ganz individuell an deinen
          Geschmack anpassen – öffne es einfach mit dem Online Smoothie
          Mixer.`}
        />
      </Helmet>
      {smoothieLoading && <Loader />}
      {/* <!-- hero banner start--> */}
      <section
        id="flx-hero-section"
        className="max-xl:after:!tw-bg-none max-lg:before:!tw-bg-none"
      >
        <div className="container">
          <div className="flx-hero-about md:!tw-pt-7 lg:!tw-pt-20 sm:!tw-pt-5 !tw-pt-3 !tw-h-auto">
            <h1 className="text-center pb-2">Finde dein Lieblingsrezept</h1>
          </div>
        </div>
      </section>

      <div className="flx-hero-about !tw-h-auto max-md:!tw-px-4">
        <p className="text-center pb-2">
          Unsere Smoothie-Rezepte sind nicht nur köstlich, sondern auch eine
          fantastische Möglichkeit, eine schnelle und gesunde Mahlzeit zu sich
          zu nehmen. Egal, ob Du ein Frühstück für unterwegs, einen Snack nach
          dem Training oder einfach nur eine erfrischende Leckerei suchst, es
          gibt ein Smoothie-Rezept für jede Gelegenheit. Du kannst jedes Rezept
          natürlich ganz individuell an deinen Geschmack anpassen – öffne es
          einfach mit dem Online Smoothie Mixer.
        </p>
      </div>
      {/* <!-- hero banner end--> */}

      {/* <!-- hero banner end--> */}
      <section id="flx-nav-pils" className="!tw-pt-10 ">
        <div className="container-fluid custom-container">
          <div className="row">
            <div className="col-12">
              {/* Tabs buton Start */}
              <ul
                className="nav nav-pills mb-5 justify-content-center flx-pils-btn"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Alle
                  </button>
                </li>
                {categories?.map((categ, index) => {
                  return (
                    <li className="nav-item" role="presentation" key={index}>
                      <button
                        className="nav-link text-capitalize"
                        //id="pills-fruit-tab"
                        data-bs-toggle="pill"
                        data-bs-target={`#pills-smoothies-${categ.id}`}
                        type="button"
                        role="tab"
                        aria-controls={`pills-smoothies-${categ.id}`}
                        aria-selected="false"
                      >
                        {categ.name}
                      </button>
                    </li>
                  );
                })}
              </ul>
              {/* Tabs Button End */}
            </div>
          </div>
          <div className="tab-content" id="pills-tabContent">
            {/* <!-- All tabs setting --> */}
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              tabIndex="0"
            >
              {/* <!------Our boxes start------> */}
              <div className="row">
                {smoothieLoading || smoothies?.length == 0
                  ? Array.from(Array(8))?.map((box, index) => {
                      return (
                        <div key={index} className="col-12 col-md-4">
                          <div
                            className="p-3"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                          >
                            <SkeltonCard />
                          </div>
                        </div>
                      );
                    })
                  : smoothies?.map((smooth, index) => {
                      return (
                        <div key={index} className="col-12 col-md-4 p-3">
                          <div
                            className=""
                            data-aos="fade-up"
                            data-aos-duration="1000"
                          >
                            <RecipeCard
                              isButton={true}
                              data={smooth}
                              actionTitle="Mehr anzeigen"
                              action={`/recipedetail/${smooth?.unique_id}`}
                            />
                          </div>
                        </div>
                      );
                    })}
              </div>
              {/* <!------Our boxes end------> */}
            </div>
            {/* <!-- All tabs setting end --> */}
            {/* <!-- fruit tabs setting --> */}
            {categories?.map((categ, index) => {
              return (
                <div
                  key={index}
                  className="tab-pane fade"
                  id={`pills-smoothies-${categ.id}`}
                  role="tabpanel"
                  //   aria-labelledby="pills-fruit-tab"
                  tabIndex={categ.id}
                >
                  <div className="row g-4">
                    {smoothies
                      ?.filter((obj) =>
                        obj?.smoothie_categories?.some(
                          (categSub) => categSub?.category_id == categ?.id
                        )
                      )
                      .map((smooth, index) => {
                        return (
                          <div className="col-12 col-md-4" key={index}>
                            <div
                              className="p-3"
                              data-aos="fade-up"
                              data-aos-duration="1000"
                            >
                              <RecipeCard
                                data={smooth}
                                isButton={true}
                                actionTitle="Mehr anzeigen"
                                action={`/recipedetail/${smooth?.unique_id}`}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}

            {/* <!--Caffeine tabs setting end --> */}
          </div>
        </div>
        {/* <div className="d-flex justify-content-center mt-5">
          <ReactPaginate
            breakLabel="..."
            nextLabel={<i className="fa-solid fa-greater-than"></i>}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={smoothies?.last_page}
            previousLabel={<i className="fa-solid fa-less-than"></i>}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            activeClassName="active"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
          />
        </div> */}
      </section>
    </div>
  );
}
