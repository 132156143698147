import React, { useLayoutEffect } from "react";
import thanku from "../assets/img/thanku.png";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function ThankYou() {
  const { pathname } = useLocation();
  // let page = searchParams.get("page");
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
      </Helmet>
      <div className="">
        <div className="container pt-5 text-center">
          <img src={thanku} className="img-fluid mt-5" />
        </div>
      </div>
    </div>
  );
}
