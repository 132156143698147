import React, { useState } from "react";
import Loader from "../../components/common/Loader";
import { useForm } from "react-hook-form";
import { signup } from "../../services/Account";
import session from "../../services/utils/session";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";

export default function Signup() {
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isVisibile, setIsVisibile] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const isFromMixer = urlParams.get("fromMixer");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setLoading(true);
    const { fName, lName, ...rest } = data;
    signup({
      ...rest,
      name: `${fName} ${lName}`,
      first_name: fName,
      last_name: lName,
    })
      .then((res) => {
        // console.log("res ", res);
        if (res?.status == 200) {
          toast.success(
            "Registrierung erfolgreich. Bitte prüfe deine E-Mails, um dein Kundenkonto zu aktivieren"
          );
          // "Register Successfuly, Please Check your  Email to verify Account"
          setTimeout(() => {
            isFromMixer === "true"
              ? navigate("/congratulations")
              : navigate("/login");
          }, 500);
        } else {
          {
            res?.response?.data?.errors?.map((err) => {
              toast.error(err);
            });
            res?.response?.data?.message &&
              toast.error(res?.response?.data?.message);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        session.clear();
        err?.response?.data.errors?.map((e) => {
          return toast.error(e);
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
      </Helmet>
      <ToastContainer />
      {loading && <Loader />}
      <section id="flx-login">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-6">
              <div className="card" style={{ borderRadius: "3rem" }}>
                <div className="card-body p-5">
                  <h3 className="mb-2 text-center text-capitalize">
                    indivit Konto erstellen
                    {/* Create Account */}
                  </h3>
                  <p className="text-center pb-4">
                    {/* Ready to drink in all the benefits? Sign up now! */}
                    Jetzt anmelden und köstliche Vorteile genießen
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-outline flx-login-icons">
                          <label className="form-label" for="form3Example1">
                            Vorname
                          </label>
                          <input
                            type="text"
                            id="form3Example1"
                            className="form-controls"
                            placeholder="Vorname"
                            {...register("fName", {
                              required: "Angabe notwendig",
                            })}
                          />
                          {errors.fName && (
                            <p className="text-danger my-1">
                              {errors.fName.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mb-4 flx-login-icons">
                        <div className="form-outline">
                          <label className="form-label" for="form3Example2">
                            Nachname
                          </label>
                          <input
                            type="text"
                            id="form3Example2"
                            className="form-controls"
                            placeholder="Nachname"
                            {...register("lName", {
                              required: "Angabe notwendig",
                            })}
                          />
                          {errors.lName && (
                            <p className="text-danger my-1">
                              {errors.lName.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <label className="form-label" for="typeEmailX">
                      E-Mail
                    </label>
                    <div className="flx-login-icons pb-3">
                      <i className="fa fa-solid fa-envelope-open flx-icon"></i>
                      <input
                        type="email"
                        id="typeEmailX"
                        className="form-control rounded"
                        placeholder="Gib hier deine E-Mail Adresse ein"
                        aria-label="email"
                        aria-describedby="email"
                        {...register("email", {
                          required: "Must be Valid Email",
                          pattern:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        })}
                      />
                      {errors.email && (
                        <p className="text-danger my-1">
                          {errors.email.message}
                        </p>
                      )}
                    </div>
                    <label className="form-label" for="typePasswordX">
                      Passwort
                    </label>
                    <div className="flx-login-icons pb-4 position-relative">
                      <i className="fa fa-solid fa-key flx-icon"></i>
                      <input
                        type={isVisibile ? "text" : "password"}
                        id="typePasswordX"
                        className="form-control rounded"
                        placeholder="Gib hier dein sicheres Passwort ein"
                        aria-label="Search"
                        aria-describedby="search-addon"
                        {...register("password", {
                          required: true,
                          // required: "Password length should be minimum 6",
                          // pattern: /^[a-zA-Z0-9-]{4,}$/,
                          minLength: 6,
                        })}
                      />
                      <i
                        className={`fa fa-solid  ${
                          isVisibile ? "fa-eye" : "fa-eye-slash"
                        }  flx-icon top-0 end-0 cursor-pointer text-black-50  `}
                        onClick={() => setIsVisibile(!isVisibile)}
                      ></i>
                      {errors?.password?.type === "Angabe notwendig" && (
                        <p className="text-danger my-1">* Angabe notwendig</p>
                      )}
                      {errors?.password?.type === "minLength" && (
                        <p className="text-danger my-1">
                          Min Length should be 6
                        </p>
                      )}
                    </div>
                    <label className="form-label" for="typePasswordX">
                      {/* Confirm Password */}
                      Passwort bestätigen
                    </label>
                    <div className="flx-login-icons pb-4 position-relative">
                      <i className="fa fa-solid fa-key flx-icon"></i>
                      <input
                        type={isVisibile ? "text" : "password"}
                        id="typePasswordX"
                        className="form-control rounded"
                        placeholder="Bestätige dein Passwort zur Sicherheit"
                        aria-label="Search"
                        aria-describedby="search-addon"
                        {...register("password_confirmation", {
                          required: true,
                          minLength: 6,
                        })}
                      />
                      <i
                        className={`fa fa-solid  ${
                          isVisibile ? "fa-eye" : "fa-eye-slash"
                        }  flx-icon top-0 end-0 cursor-pointer text-black-50`}
                        onClick={() => setIsVisibile(!isVisibile)}
                      ></i>
                      {errors?.password_confirmation?.type ===
                        "Angabe notwendig" && (
                        <p className="text-danger my-1">* Angabe notwendig</p>
                      )}
                      {errors?.password_confirmation?.type === "minLength" && (
                        <p className="text-danger my-1">
                          Min Length should be 6
                        </p>
                      )}
                    </div>
                    <button
                      className="btn-solid-success w-100 mb-4"
                      type="submit"
                    >
                      {/* Signup */}
                      Registrieren
                    </button>
                  </form>
                  <p className="mb-0 text-center">
                    {/* Already have an account? */}
                    Du hast bereits ein Kundenkonto?
                    <a href="login" className="text-theme-success">
                      &nbsp; &nbsp;Anmelden
                      {/* Login */}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
