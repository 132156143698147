import React from "react";
import TermsConditionsDetails from "./TermsConditionsDetails";
import { useLayoutEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { getTermsPage } from "../../services/StaticPages";
import HeroBanner from "../../components/common/HeroBanner";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Loader from '../../components/common/Loader'

export default function TermsConditions() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { isLoading, error, data } = useQuery({
    queryKey: ["termsPage"],
    queryFn: getTermsPage,
  });
  let res =
    data?.data?.data?.length > 0 ? data?.data && data?.data?.data[0] : {};

  let resd = res?.title_text?.split("||");

  let resdes1 = resd ? resd[0] : "";
  let resdes2 = resd ? resd[1] : "";
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
      </Helmet>
{isLoading && <Loader /> }
      <HeroBanner
        data={{
          title: res?.title,
          description: resdes1,
          description2: resdes2,
        }}
      />
      <TermsConditionsDetails
        data={{
          title: res?.heading,
          description: res?.content,
        }}
      />
    </div>
  );
}
