import React from "react";
import wustainableIcon from "../../assets/img/sustainability.png";
import waterIcon from "../../assets/img/water.png";
import energyIcon from "../../assets/img/energy.png";
import recycleIcon from "../../assets/img/recycle.png";
import sustainableImg from "../../assets/img/sustaina.png";
import icon1 from "../../assets/icon/bio_g.png";
import icon2 from "../../assets/icon/recycling-zeichen_g.png";
import icon3 from "../../assets/icon/blut_g.png";
import icon4 from "../../assets/icon/mull_g.png";

import { Link } from "react-router-dom";
export default function SustainableSection() {
  return (
    <div>
      {/* <!------full width section text Sustainability start------> */}
      <section id="flx-text-section">
        <div className="container-fluid">
          <div className="row d-flex align-items-stretch">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 p-0 tw-h-auto">
              <img
                src={sustainableImg}
                className="tw-w-[fill-available] tw-w-[-moz-available] tw-h-full tw-object-cover"
                loading="lazy"
                alt="Sustainable"
                title="sustainable"
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 p-0 py-4 tw-flex tw-items-center">
              <div className="tw-px-4 md:tw-ps-6 lg:tw-ps-12">
                <div className="pb-3">
                  <h2 data-aos="fade-up" data-aos-duration="1000">
                    Nachhaltigkeit
                  </h2>
                  <p data-aos="fade-up" data-aos-duration="1500">
                    Liegt uns am Herzen
                  </p>
                </div>
                <div className="flx-iconbox">
                  <ul className="mb-1">
                    <li>
                      <img
                        src={icon1}
                        alt=""
                        className="img-fluid"
                        loading="lazy"
                      />
                    </li>
                    <li className="ps-4">
                      <h4 className="">100% transparente Bio-Zutaten</h4>
                      <p>
                        Umweltschutz durch reine Verwendung von Zutaten, nach
                        den Richtlinien des ökologischen Landbaus
                      </p>
                    </li>
                  </ul>
                  <ul className="mb-1">
                    <li>
                      <img
                        src={icon2}
                        alt=""
                        className="img-fluid"
                        loading="lazy"
                      />
                    </li>
                    <li className="ps-4">
                      <h4 className="">
                        Vollständige Weiterverwertung der Verpackung
                      </h4>
                      <p>
                        Beitrag zu einem nachhaltigem Kreislauf durch maximalen
                        Einsatz von recyceltem und recyclingfähigem Material
                      </p>
                    </li>
                  </ul>
                  <ul className="mb-1">
                    <li>
                      <img
                        src={icon3}
                        alt=""
                        className="img-fluid"
                        loading="lazy"
                      />
                    </li>
                    <li className="ps-4">
                      <h4 className="">Effizienter Einsatz von Ressourcen</h4>
                      <p>
                        Wir wollen Ressourcen sinnvoll nutzen, um für alle das
                        bestmögliche Ergebnis zu erzielen – in der höchsten
                        Qualität
                      </p>
                    </li>
                  </ul>
                  <ul className="mb-1">
                    <li>
                      <img
                        src={icon4}
                        alt=""
                        className="img-fluid"
                        loading="lazy"
                      />
                    </li>
                    <li className="ps-4">
                      <h4 className="">
                        Konsequente Reduzierung von Verschwendung
                      </h4>
                      <p>
                        Vermeidung von Abfall in allen Stufen der Wertschöpfung
                        – zu jedem Zeitpunkt
                      </p>
                    </li>
                  </ul>
                  <ul className="mb-1">
                    <li>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</li>
                    <li className="ps-4">
                      <Link
                        to="/sustainability"
                        className="btn btn-link btn-solid-success-color"
                      >
                        Mehr erfahren
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!------full width section Sustainability end------> */}
    </div>
  );
}
