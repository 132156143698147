import { useEffect, useState } from "react";

const useIngTooltip = (data, e) => {
  const [tooltipData, setTooltipData] = useState({
    ananas: [
      { min: 0, max: 1, title: null },
      { min: 1, max: 1.5, title: null },
      { min: 1.5, max: 2, title: null },
      { min: 2, max: 3, title: null },
      { min: 3, max: 4, title: null },
      { min: 4, max: 6, title: "ca. ein haselnussgroßes Stück Ananas" },
      { min: 6, max: 8, title: "ca. ein haselnussgroßes Stück Ananas" },
      {
        min: 8,
        max: 12,
        title: "ca. 1/8 Scheibe (1cm) einer mittelgroßen Ananas",
      },
      { min: 12, max: 16, title: "ca. ein wallnussgroßes Stück Ananas" },
      {
        min: 16,
        max: 20,
        title: "ca. 1/4 Scheibe (1cm) einer mittelgroßen Ananas",
      },
      { min: 20, max: 26, title: "ca. 1/4 Scheibe (1cm) einer großen Ananas" },
      { min: 26, max: 33, title: "ca. 1/2 Scheibe (1cm) einer kleinen Ananas" },
      {
        min: 33,
        max: 40,
        title: "ca. 1/2 Scheibe (1cm) einer mittelgroßen Ananas",
      },
      { min: 40, max: 50, title: "ca. 1/2 Scheibe (1cm) einer großen Ananas" },
      {
        min: 50,
        max: 60,
        title: "ca. eine Scheibe (1cm) einer kleinen Ananas",
      },
      {
        min: 60,
        max: 70,
        title: "ca. eine Scheibe (1cm) einer mittelgroßen Ananas",
      },
      { min: 70, max: 80, title: "ca. eine Scheibe (1cm) einer großen Ananas" },
      {
        min: 80,
        max: 90,
        title: "ca. 1 1/2 Scheiben (1cm) einer kleinen Ananas",
      },
      {
        min: 90,
        max: 100,
        title: "ca. 1 1/2 Scheiben (1cm) einer mittelgroßen Ananas",
      },
      {
        min: 100,
        max: 110,
        title: "ca. 1 1/2 Scheiben (1cm) einer großen Ananas",
      },
      {
        min: 110,
        max: 120,
        title: "ca. zwei Scheiben (1cm) einer kleinen Ananas",
      },
      {
        min: 120,
        max: 130,
        title: "ca. zwei Scheiben (1cm) einer mittelgroßen Ananas",
      },
      {
        min: 130,
        max: 140,
        title: "ca. zwei Scheiben (1cm) einer mittelgroßen Ananas",
      },
      {
        min: 140,
        max: 150,
        title: "ca. zwei Scheiben (1cm) einer großen Ananas",
      },
      {
        min: 150,
        max: 160,
        title: "ca. zwei Scheiben (1cm) einer großen Ananas",
      },
      {
        min: 160,
        max: 170,
        title: "ca. drei Scheiben (1cm) einer kleinen Ananas",
      },
      {
        min: 170,
        max: 180,
        title: "ca. drei Scheiben (1cm) einer kleinen Ananas",
      },
      {
        min: 180,
        max: 190,
        title: "ca. drei Scheiben (1cm) einer mittelgroßen Ananas",
      },
      {
        min: 190,
        max: 200,
        title: "ca. drei Scheiben (1cm) einer mittelgroßen Ananas",
      },
      {
        min: 200,
        max: 210,
        title: "ca. drei Scheiben (1cm) einer mittelgroßen Ananas",
      },
      {
        min: 210,
        max: 220,
        title: "ca. drei Scheiben (1cm) einer großen Ananas",
      },
      { min: 220, max: 230, title: "ca. 1/3 einer kleinen Ananas" },
      { min: 230, max: 240, title: "ca. 1/3 einer kleinen Ananas" },
      { min: 240, max: 250, title: "ca. 1/3 einer mittelgroßen Ananas" },
    ],

    apfel: [
      { min: 0, max: 1, title: null },
      { min: 1, max: 1.5, title: null },
      { min: 1.5, max: 2, title: null },
      { min: 2, max: 3, title: null },
      { min: 3, max: 4, title: null },
      {
        min: 4,
        max: 6,
        title: "ca. 1/4 Spalte eines mittelgroßen ungeschälten Apfels",
      },
      {
        min: 6,
        max: 8,
        title: "ca. 1/2 Spalte eines kleinen ungeschälten Apfels",
      },
      {
        min: 8,
        max: 12,
        title: "ca. 1/2 Spalte eines mittelgroßen ungeschälten Apfels",
      },
      {
        min: 12,
        max: 16,
        title: "ca. 1/2 Spalte eines großen ungeschälten Apfels",
      },
      {
        min: 16,
        max: 20,
        title: "ca. eine Spalte eines mittelgroßen ungeschälten Apfels",
      },
      {
        min: 20,
        max: 26,
        title: "ca. eine Spalte eines großen ungeschälten Apfels",
      },
      { min: 26, max: 33, title: "ca. 1/4 eines kleinen ungeschälten Apfels" },
      {
        min: 33,
        max: 40,
        title: "ca. 1/4 eines mittelgroßen ungeschälten Apfels",
      },
      { min: 40, max: 50, title: "ca. 1/4 eines großen ungeschälten Apfels" },
      { min: 50, max: 60, title: "ca. 1/2 eines kleinen ungeschälten Apfels" },
      {
        min: 60,
        max: 70,
        title: "ca. 1/2 eines mittelgroßen ungeschälten Apfels",
      },
      { min: 70, max: 80, title: "ca. 3/4 eines kleinen ungeschälten Apfels" },
      {
        min: 80,
        max: 90,
        title: "ca. 2/3 eines mittelgroßen ungeschälten Apfels",
      },
      { min: 90, max: 100, title: "ca. 1/2 eines großen ungeschälten Apfels" },
      { min: 100, max: 110, title: "ca. ein kleiner ungeschälter Apfel" },
      {
        min: 110,
        max: 120,
        title: "ca. ein kleiner-mittelgroßer ungeschälter Apfel",
      },
      { min: 120, max: 130, title: "ca. ein mittelgroßer ungeschälter Apfel" },
      { min: 130, max: 140, title: "ca. ein mittelgroßer ungeschälter Apfel" },
      { min: 140, max: 150, title: "ca. 1 1/2 kleine ungeschälte Äpfel" },
      {
        min: 150,
        max: 160,
        title: "ca. ein mittelgroßer-großer ungeschälter Apfel",
      },
      {
        min: 160,
        max: 170,
        title: "ca. ein mittelgroßer-großer ungeschälter Apfel",
      },
      { min: 170, max: 180, title: "ca. ein großer ungeschälter Apfel" },
      { min: 180, max: 190, title: "ca. ein großer ungeschälter Apfel" },
      { min: 190, max: 200, title: "ca. 1 1/2 mittelgroße ungeschälte Äpfel" },
      { min: 200, max: 210, title: "ca. zwei kleine ungeschälte Äpfel" },
      { min: 210, max: 220, title: "ca. zwei kleine ungeschälte Äpfel" },
      {
        min: 220,
        max: 230,
        title: "ca. zwei kleine-mittelgroße ungeschälte Äpfel",
      },
      { min: 230, max: 240, title: "ca. zwei mittelgroße ungeschälte Äpfel" },
      { min: 240, max: 250, title: "ca. zwei mittelgroße ungeschälte Äpfel" },
    ],

    avocado: [
      { min: 0, max: 1, title: null },
      { min: 1, max: 1.5, title: null },
      { min: 1.5, max: 2, title: null },
      { min: 2, max: 3, title: null },
      { min: 3, max: 4, title: null },
      { min: 4, max: 6, title: "ca. ein haselnussgroßes Stück Avocado" },
      { min: 6, max: 8, title: "ca. ein haselnussgroßes Stück Avocado" },
      { min: 8, max: 12, title: "ca. zwei haselnussgroße Stücke Avocado" },
      { min: 12, max: 16, title: "ca. ein wallnussgroßes Stück Avocado" },
      { min: 16, max: 20, title: "ca. drei haselnussgroße Stücke Avocado" },
      { min: 20, max: 26, title: "ca. 1/4 einer kleinen Avocado" },
      { min: 26, max: 33, title: "ca. 1/4 einer kleinen-mittelgroßen Avocado" },
      { min: 33, max: 40, title: "ca. 1/4 einer mittelgroßen Avocado" },
      { min: 40, max: 50, title: "ca. 1/2 einer kleinen Avocado" },
      { min: 50, max: 60, title: "ca. 1/4 einer großen Avocado" },
      { min: 60, max: 70, title: "ca. 1/4 einer großen Avocado" },
      { min: 70, max: 80, title: "ca. 1/2 einer mittelgroßen Avocado" },
      { min: 80, max: 90, title: "ca. 1/2 einer mittelgroßen Avocado" },
      { min: 90, max: 100, title: "ca. eine kleine Avocado" },
      { min: 100, max: 110, title: "ca. 1/2 einer großen Avocado" },
      { min: 110, max: 120, title: "ca. 1/2 einer großen Avocado" },
      { min: 120, max: 130, title: "ca. eine kleine-mittelgroße Avocado" },
      { min: 130, max: 140, title: "ca. eine kleine-mittelgroße Avocado" },
      { min: 140, max: 150, title: "ca. 1 1/2 kleine Avocados" },
      { min: 150, max: 160, title: "ca. eine mittelgroße Avocado" },
      { min: 160, max: 170, title: "ca. eine mittelgroße Avocado" },
      { min: 170, max: 180, title: "ca. eine mittelgroße Avocado" },
      { min: 180, max: 190, title: "ca. eine mittelgroße-große Avocado" },
      { min: 190, max: 200, title: "ca. eine mittelgroße-große Avocado" },
      { min: 200, max: 210, title: "ca. eine große Avocado" },
      { min: 210, max: 220, title: "ca. eine große Avocado" },
      { min: 220, max: 230, title: "ca. eine große Avocado" },
      { min: 230, max: 240, title: "ca. 1 1/2 mittelgroße Avocados" },
      { min: 240, max: 250, title: "ca. 1 1/2 mittelgroße Avocados" },
    ],

    banane: [
      { min: 0, max: 1, title: null },
      { min: 1, max: 1.5, title: null },
      { min: 1.5, max: 2, title: null },
      { min: 2, max: 3, title: null },
      { min: 3, max: 4, title: null },
      {
        min: 4,
        max: 6,
        title: "ca. eine Scheibe (1cm) einer kleinen reifen Banane",
      },
      {
        min: 6,
        max: 8,
        title: "ca. eine Scheibe (1cm) einer mittelgroßen reifen Banane",
      },
      {
        min: 8,
        max: 12,
        title: "ca. eine Scheibe (1cm) einer großen reifen Banane",
      },
      {
        min: 12,
        max: 16,
        title: "ca. zwei Scheiben (1cm) einer mittelgroßen reifen Banane",
      },
      { min: 16, max: 20, title: "ca. 1/4 einer kleinen reifen Banane" },
      { min: 20, max: 26, title: "ca. 1/4 einer mittelgroßen reifen Banane" },
      { min: 26, max: 33, title: "ca. 1/4 einer großen reifen Banane" },
      { min: 33, max: 40, title: "ca. 1/2 einer kleinen reifen Banane" },
      { min: 40, max: 50, title: "ca. 1/2 einer mittelgroßen reifen Banane" },
      {
        min: 50,
        max: 60,
        title: "ca. 1/2 einer mittelgroßen-großen reifen Banane",
      },
      { min: 60, max: 70, title: "ca. 1/2 einer großen reifen Banane" },
      { min: 70, max: 80, title: "ca. eine kleine reife Banane" },
      { min: 80, max: 90, title: "ca. eine mittelgroße reife Banane" },
      { min: 90, max: 100, title: "ca. eine mittelgroße reife Banane" },
      { min: 100, max: 110, title: "ca. 1 1/2 kleine reife Bananen" },
      { min: 110, max: 120, title: "ca. 1 1/2 kleine reife Bananen" },
      { min: 120, max: 130, title: "ca. eine große reife Banane" },
      { min: 130, max: 140, title: "ca. eine große reife Banane" },
      { min: 140, max: 150, title: "ca. zwei kleine reife Bananen" },
      { min: 150, max: 160, title: "ca. zwei kleine reife Bananen" },
      {
        min: 160,
        max: 170,
        title: "ca. zwei kleine-mittelgroße reife Bananen",
      },
      { min: 170, max: 180, title: "ca. zwei mittelgroße reife Bananen" },
      { min: 180, max: 190, title: "ca. zwei mittelgroße reife Bananen" },
      { min: 190, max: 200, title: "ca. 1 1/2 große reife Bananen" },
      { min: 200, max: 210, title: "ca. 1 1/2 große reife Bananen" },
      { min: 210, max: 220, title: "ca. drei kleine reife Bananen" },
      { min: 220, max: 230, title: "ca. drei kleine reife Bananen" },
      {
        min: 230,
        max: 240,
        title: "ca. drei kleine-mittelgroße reife Bananen",
      },
      { min: 240, max: 250, title: "ca. zwei große reife Bananen" },
    ],

    birne: [
      { min: 0, max: 1, title: null },
      { min: 1, max: 1.5, title: null },
      { min: 1.5, max: 2, title: null },
      { min: 2, max: 3, title: null },
      { min: 3, max: 4, title: null },
      { min: 4, max: 6, title: null },
      { min: 6, max: 8, title: null },
      { min: 8, max: 12, title: null },
      { min: 12, max: 16, title: null },
      { min: 16, max: 20, title: null },
      { min: 20, max: 26, title: null },
      { min: 26, max: 33, title: null },
      { min: 33, max: 40, title: null },
      { min: 40, max: 50, title: null },
      { min: 50, max: 60, title: null },
      { min: 60, max: 70, title: null },
      { min: 70, max: 80, title: null },
      { min: 80, max: 90, title: null },
      { min: 90, max: 100, title: null },
      { min: 100, max: 110, title: null },
      { min: 110, max: 120, title: null },
      { min: 120, max: 130, title: null },
      { min: 130, max: 140, title: null },
      { min: 140, max: 150, title: null },
      { min: 150, max: 160, title: null },
      { min: 160, max: 170, title: null },
      { min: 170, max: 180, title: null },
      { min: 180, max: 190, title: null },
      { min: 190, max: 200, title: null },
      { min: 200, max: 210, title: null },
      { min: 210, max: 220, title: null },
      { min: 220, max: 230, title: null },
      { min: 230, max: 240, title: null },
      { min: 240, max: 250, title: null },
    ],
    brombeere: [],
    erdbeere: [],
    feige: [],
    granatapfel: [],
    heidelbeere: [],
    himbeere: [
      { min: 0, max: 1, title: null },
      { min: 1, max: 1.5, title: null },
      { min: 1.5, max: 2, title: null },
      { min: 2, max: 3, title: null },
      { min: 3, max: 4, title: null },
      { min: 4, max: 6, title: "ca. eine mittelgroße Himbeere" },
      { min: 6, max: 8, title: "ca. eine große Himbeere" },
      { min: 8, max: 12, title: "ca. zwei mittelgroße Himbeeren" },
      { min: 12, max: 16, title: "ca. zwei große Himbeeren" },
      { min: 16, max: 20, title: "ca. drei große Himbeeren" },
      { min: 20, max: 26, title: "ca. fünf mittelgroße Himbeeren" },
      { min: 26, max: 33, title: "ca. fünf große Himbeeren" },
      { min: 33, max: 40, title: "ca. eine kleine handvoll Himbeeren" },
      { min: 40, max: 50, title: "ca. eine handvoll Himbeeren" },
      { min: 50, max: 60, title: "ca. eine große handvoll Himbeeren" },
      { min: 60, max: 70, title: "ca. 1 1/2 handvoll Himbeeren" },
      { min: 70, max: 80, title: "ca. zwei kleine handvoll Himbeeren" },
      { min: 80, max: 90, title: "ca. zwei handvoll Himbeeren" },
      { min: 90, max: 100, title: "ca. zwei handvoll Himbeeren" },
      { min: 100, max: 110, title: "ca. zwei große handvoll Himbeeren" },
      { min: 110, max: 120, title: "ca. drei kleine handvoll Himbeeren" },
      { min: 120, max: 130, title: "ca. drei handvoll Himbeeren" },
      { min: 130, max: 140, title: "ca. drei handvoll Himbeeren" },
      { min: 140, max: 150, title: "ca. drei große handvoll Himbeeren" },
      { min: 150, max: 160, title: "ca. drei große handvoll Himbeeren" },
      { min: 160, max: 170, title: "ca. vier kleine handvoll Himbeeren" },
      { min: 170, max: 180, title: "ca. vier handvoll Himbeeren" },
      { min: 180, max: 190, title: "ca. vier handvoll Himbeeren" },
      { min: 190, max: 200, title: "ca. fünf kleine handvoll Himbeeren" },
      { min: 200, max: 210, title: "ca. vier große handvoll Himbeeren" },
      { min: 210, max: 220, title: "ca. vier große handvoll Himbeeren" },
      { min: 220, max: 230, title: "ca. fünf handvoll Himbeeren" },
      { min: 230, max: 240, title: "ca. fünf handvoll Himbeeren" },
      { min: 240, max: 250, title: "ca. fünf große handvoll Himbeeren" },
    ],
    honigmelone: [],
    kiwi: [],
    litchi: [],
    mango: [
      { min: 0, max: 1, title: null },
      { min: 1, max: 1.5, title: null },
      { min: 1.5, max: 2, title: null },
      { min: 2, max: 3, title: null },
      { min: 3, max: 4, title: null },
      { min: 4, max: 6, title: "ca. ein haselnussgroßes Stück Mango" },
      { min: 6, max: 8, title: "ca. ein haselnussgroßes Stück Mango" },
      { min: 8, max: 12, title: "ca. zwei haselnussgroße Stücke Mango" },
      { min: 12, max: 16, title: "ca. ein walnussgroßes Stück Mango" },
      { min: 16, max: 20, title: "ca. drei haselnussgroße Stücke Mango" },
      { min: 20, max: 26, title: "ca. vier haselnussgroße Stücke Mango" },
      { min: 26, max: 33, title: "ca. zwei walnussgroße Stücke Mango" },
      { min: 33, max: 40, title: "ca. sechs haselnussgroße Stücke Mango" },
      { min: 40, max: 50, title: "ca. 1/4 einer kleinen Mango" },
      { min: 50, max: 60, title: "ca. 1/4 einer kleinen-mittelgroßen Mango" },
      { min: 60, max: 70, title: "ca. 1/4 einer mittelgroßen Mango" },
      { min: 70, max: 80, title: "ca. 1/4 einer mittelgroßen Mango" },
      { min: 80, max: 90, title: "ca. 1/4 einer mittelgroßen-großen Mango" },
      { min: 90, max: 100, title: "ca. 1/2 einer kleinen Mango" },
      { min: 100, max: 110, title: "ca. 1/4 einer großen Mango" },
      { min: 110, max: 120, title: "ca. 1/4 einer großen Mango" },
      { min: 120, max: 130, title: "ca. 1/2 einer kleinen-mittelgroßen Mango" },
      { min: 130, max: 140, title: "ca. 1/2 einer mittelgroßen Mango" },
      { min: 140, max: 150, title: "ca. 1/2 einer mittelgroßen Mango" },
      { min: 150, max: 160, title: "ca. 3/4 einer kleinen Mango" },
      { min: 160, max: 170, title: "ca. 1/2 einer mittelgroßen-großen Mango" },
      { min: 170, max: 180, title: "ca. 1/2 einer mittelgroßen-großen Mango" },
      { min: 180, max: 190, title: "ca. 3/4 einer mittelgroßen Mango" },
      { min: 190, max: 200, title: "ca. eine kleine Mango" },
      { min: 200, max: 210, title: "ca. eine kleine Mango" },
      { min: 210, max: 220, title: "ca. 1/2 einer großen Mango" },
      { min: 220, max: 230, title: "ca. 1/2 einer großen Mango" },
      { min: 230, max: 240, title: "ca. eine kleine-mittelgroße Mango" },
      { min: 240, max: 250, title: "ca. eine mittelgroße Mango" },
    ],
    papaya: [],
    pfirsich: [],
    wassermelone: [],
    brokkoli: [
      { min: 0, max: 1, title: null },
      { min: 1, max: 1.5, title: null },
      { min: 1.5, max: 2, title: null },
      { min: 2, max: 3, title: null },
      { min: 3, max: 4, title: null },
      { min: 4, max: 6, title: "ca. 1/2 kleines Brokkoli-Röschen" },
      { min: 6, max: 8, title: "ca. 1/2 mittelgroßes Brokkoli-Röschen" },
      { min: 8, max: 12, title: "ca. ein kleines Brokkoli-Röschen" },
      {
        min: 12,
        max: 16,
        title: "ca. ein kleines-mittelgroßes Brokkoli-Röschen",
      },
      { min: 16, max: 20, title: "ca. ein mittelgroßes Brokkoli-Röschen" },
      { min: 20, max: 26, title: "ca. ein großes Brokkoli-Röschen" },
      { min: 26, max: 33, title: "ca. zwei mittelgroße Brokkoli-Röschen" },
      { min: 33, max: 40, title: "ca. eine kleine handvoll Brokkoli-Röschen" },
      { min: 40, max: 50, title: "ca. zwei große Brokkoli-Röschen" },
      {
        min: 50,
        max: 60,
        title: "ca. eine kleine-mittelgroße handvoll Brokkoli-Röschen",
      },
      {
        min: 60,
        max: 70,
        title: "ca. eine mittelgroße handvoll Brokkoli-Röschen",
      },
      { min: 70, max: 80, title: "ca. drei große Brokkoli-Röschen" },
      { min: 80, max: 90, title: "ca. zwei kleine handvoll Brokkoli-Röschen" },
      { min: 90, max: 100, title: "ca. eine große handvoll Brokkoli-Röschen" },
      { min: 100, max: 110, title: "ca. eine große handvoll Brokkoli-Röschen" },
      {
        min: 110,
        max: 120,
        title: "ca. drei kleine handvoll Brokkoli-Röschen",
      },
      { min: 120, max: 130, title: "ca. 1/2 kleiner Brokkoli-Kopf" },
      {
        min: 130,
        max: 140,
        title: "ca. zwei mittelgroße handvoll Brokkoli-Röschen",
      },
      {
        min: 140,
        max: 150,
        title: "ca. zwei mittelgroße handvoll Brokkoli-Röschen",
      },
      {
        min: 150,
        max: 160,
        title: "ca. vier kleine handvoll Brokkoli-Röschen",
      },
      {
        min: 160,
        max: 170,
        title: "ca. vier kleine handvoll Brokkoli-Röschen",
      },
      { min: 170, max: 180, title: "ca. zwei große handvoll Brokkoli-Röschen" },
      { min: 180, max: 190, title: "ca. zwei große handvoll Brokkoli-Röschen" },
      {
        min: 190,
        max: 200,
        title: "ca. drei mittelgroße handvoll Brokkoli-Röschen",
      },
      {
        min: 200,
        max: 210,
        title: "ca. drei mittelgroße handvoll Brokkoli-Röschen",
      },
      {
        min: 210,
        max: 220,
        title: "ca. drei mittelgroße-große handvoll Brokkoli-Röschen",
      },
      {
        min: 220,
        max: 230,
        title: "ca. drei mittelgroße-große handvoll Brokkoli-Röschen",
      },
      { min: 230, max: 240, title: "ca. ein kleiner Brokkoli-Kopf" },
      { min: 240, max: 250, title: "ca. ein kleiner Brokkoli-Kopf" },
    ],
    salatgurke: [],
    karotte: [],
    kürbis: [],
    paprika: [],
    tomate: [],
    rhabarber: [],
    "rote bete": [],
    sellerie: [],
    feldsalat: [],
    grunkohl: [],
    babyspinat: [
      { min: 0, max: 1, title: null },
      { min: 1, max: 1.5, title: null },
      { min: 1.5, max: 2, title: null },
      { min: 2, max: 3, title: null },
      { min: 3, max: 4, title: null },
      { min: 4, max: 6, title: "ca. 8 junge Spinat-Blättchen" },
      { min: 6, max: 8, title: "ca. 12 junge Spinat-Blättchen" },
      { min: 8, max: 12, title: "ca. 20 junge Spinat-Blättchen" },
      {
        min: 12,
        max: 16,
        title: "ca. 1/2 kleine handvoll junge Spinat-Blättchen",
      },
      {
        min: 16,
        max: 20,
        title: "ca. 1/2 mittelgroße handvoll junge Spinat-Blättchen",
      },
      {
        min: 20,
        max: 26,
        title: "ca. 1/2 große handvoll junge Spinat-Blättchen",
      },
      {
        min: 26,
        max: 33,
        title: "ca. eine kleine handvoll junge Spinat-Blättchen",
      },
      {
        min: 33,
        max: 40,
        title: "ca. eine große handvoll junge Spinat-Blättchen",
      },
      {
        min: 40,
        max: 50,
        title: "ca. zwei kleine handvoll junge Spinat-Blättchen",
      },
      { min: 50, max: 60, title: null },
      { min: 60, max: 70, title: null },
      { min: 70, max: 80, title: null },
      { min: 80, max: 90, title: null },
      { min: 90, max: 100, title: null },
      { min: 100, max: 110, title: null },
      { min: 110, max: 120, title: null },
      { min: 120, max: 130, title: null },
      { min: 130, max: 140, title: null },
      { min: 140, max: 150, title: null },
      { min: 150, max: 160, title: null },
      { min: 160, max: 170, title: null },
      { min: 170, max: 180, title: null },
      { min: 180, max: 190, title: null },
      { min: 190, max: 200, title: null },
      { min: 200, max: 210, title: null },
      { min: 210, max: 220, title: null },
      { min: 220, max: 230, title: null },
      { min: 230, max: 240, title: null },
      { min: 240, max: 250, title: null },
    ],
    grapefruit: [],
    limette: [],
    zitrone: [
      { min: 0, max: 1, title: null },
      { min: 1, max: 1.5, title: null },
      { min: 1.5, max: 2, title: null },
      { min: 2, max: 3, title: null },
      { min: 3, max: 4, title: null },
      {
        min: 4,
        max: 6,
        title: "ca. 1/2 Scheibe (1cm) einer kleinen geschälten Zitrone",
      },
      {
        min: 6,
        max: 8,
        title: "ca. 1/2 Scheibe (1cm) einer mittelgroßen geschälten Zitrone",
      },
      {
        min: 8,
        max: 12,
        title: "ca. 1/2 Scheibe (1cm) einer großen geschälten Zitrone",
      },
      {
        min: 12,
        max: 16,
        title: "ca. eine Scheibe (1cm) einer mittelgroßen geschälten Zitrone",
      },
      {
        min: 16,
        max: 20,
        title: "ca. eine Scheibe (1cm) einer großen geschälten Zitrone",
      },
      {
        min: 20,
        max: 26,
        title: "ca. zwei Scheiben (1cm) einer mittelgroßen geschälten Zitrone",
      },
      { min: 26, max: 33, title: "ca. eine kleine geschälte Zitrone" },
      { min: 33, max: 40, title: "ca. eine mittelgroße geschälte Zitrone" },
      { min: 40, max: 50, title: null },
      { min: 50, max: 60, title: null },
      { min: 60, max: 70, title: null },
      { min: 70, max: 80, title: null },
      { min: 80, max: 90, title: null },
      { min: 90, max: 100, title: null },
      { min: 100, max: 110, title: null },
      { min: 110, max: 120, title: null },
      { min: 120, max: 130, title: null },
      { min: 130, max: 140, title: null },
      { min: 140, max: 150, title: null },
      { min: 150, max: 160, title: null },
      { min: 160, max: 170, title: null },
      { min: 170, max: 180, title: null },
      { min: 180, max: 190, title: null },
      { min: 190, max: 200, title: null },
      { min: 200, max: 210, title: null },
      { min: 210, max: 220, title: null },
      { min: 220, max: 230, title: null },
      { min: 230, max: 240, title: null },
      { min: 240, max: 250, title: null },
    ],
    ingwer: [
      { min: 0, max: 1, title: null },
      { min: 1, max: 1.5, title: "ca. ein erdnussgroßes Stück Ingwerwurzel" },
      { min: 1.5, max: 2, title: "ca. drei rosinengroße Stücke Ingwerwurzel" },
      { min: 2, max: 3, title: "ca. zwei erdnussgroße Stücke Ingwerwurzel" },
      { min: 3, max: 4, title: "ca. fünf rosinengroße Stücke Ingwerwurzel" },
      { min: 4, max: 6, title: "ca. ein haselnussgroßes Stück Ingwerwurzel" },
      { min: 6, max: 8, title: null },
      { min: 8, max: 12, title: null },
      { min: 12, max: 16, title: null },
      { min: 16, max: 20, title: null },
      { min: 20, max: 26, title: null },
      { min: 26, max: 33, title: null },
      { min: 33, max: 40, title: null },
      { min: 40, max: 50, title: null },
      { min: 50, max: 60, title: null },
      { min: 60, max: 70, title: null },
      { min: 70, max: 80, title: null },
      { min: 80, max: 90, title: null },
      { min: 90, max: 100, title: null },
      { min: 100, max: 110, title: null },
      { min: 110, max: 120, title: null },
      { min: 120, max: 130, title: null },
      { min: 130, max: 140, title: null },
      { min: 140, max: 150, title: null },
      { min: 150, max: 160, title: null },
      { min: 160, max: 170, title: null },
      { min: 170, max: 180, title: null },
      { min: 180, max: 190, title: null },
      { min: 190, max: 200, title: null },
      { min: 200, max: 210, title: null },
      { min: 210, max: 220, title: null },
      { min: 220, max: 230, title: null },
      { min: 230, max: 240, title: null },
      { min: 240, max: 250, title: null },
    ],
    kurkuma: [],
    basilikum: [],
    minze: [],
    zitronenmelisse: [],
    ahornsirup: [],
    brennnessel: [],
    hagebutte: [],
    hanföl: [],
    blütenhonig: [],
    mandelmus: [
      { min: 0, max: 1, title: null },
      { min: 1, max: 1.5, title: null },
      { min: 1.5, max: 2, title: null },
      { min: 2, max: 3, title: null },
      { min: 3, max: 4, title: null },
      { min: 4, max: 6, title: null },
      {
        min: 6,
        max: 8,
        title: "fein püriertes Mus aus ca. 1/4 handvoll ungeschälter Mandeln",
      },
      {
        min: 8,
        max: 12,
        title: "fein püriertes Mus aus ca. 10 ungeschälten Mandeln",
      },
      {
        min: 12,
        max: 16,
        title: "fein püriertes Mus aus ca. 1/2 handvoll ungeschälter Mandeln",
      },
      {
        min: 16,
        max: 20,
        title:
          "fein püriertes Mus aus ca. einer kleinen handvoll ungeschälter Mandeln",
      },
      {
        min: 20,
        max: 26,
        title: "fein püriertes Mus aus ca. einer handvoll ungeschälter Mandeln",
      },
      { min: 26, max: 33, title: null },
      { min: 33, max: 40, title: null },
      { min: 40, max: 50, title: null },
      { min: 50, max: 60, title: null },
      { min: 60, max: 70, title: null },
      { min: 70, max: 80, title: null },
      { min: 80, max: 90, title: null },
      { min: 90, max: 100, title: null },
      { min: 100, max: 110, title: null },
      { min: 110, max: 120, title: null },
      { min: 120, max: 130, title: null },
      { min: 130, max: 140, title: null },
      { min: 140, max: 150, title: null },
      { min: 150, max: 160, title: null },
      { min: 160, max: 170, title: null },
      { min: 170, max: 180, title: null },
      { min: 180, max: 190, title: null },
      { min: 190, max: 200, title: null },
      { min: 200, max: 210, title: null },
      { min: 210, max: 220, title: null },
      { min: 220, max: 230, title: null },
      { min: 230, max: 240, title: null },
      { min: 240, max: 250, title: null },
    ],

    maracuja: [],
    preiselbeere: [],
    sanddornbeere: [],
    weizengrassaft: [],
    gemüsebrühe: [],
    hafermilch: [],
    kokoswasser: [
      { min: 0, max: 1, title: null },
      { min: 1, max: 1.5, title: null },
      { min: 1.5, max: 2, title: null },
      { min: 2, max: 3, title: null },
      {
        min: 3,
        max: 4,
        title: "ca. ein Spritzer reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 4,
        max: 6,
        title: "ca. zwei Spritzer reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 6,
        max: 8,
        title: "ca. ein Schuss reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 8,
        max: 12,
        title: "ca. drei-vier Spritzer reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 12,
        max: 16,
        title: "ca. fünf Spritzer reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 16,
        max: 20,
        title:
          "ca. ein ordentlicher Schuss reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 20,
        max: 26,
        title:
          "ca. ein gutes Schnapsglas reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 26,
        max: 33,
        title: "ca. ein großer Schluck reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 33,
        max: 40,
        title:
          "ca. zwei knappe Schnapsgläser reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 40,
        max: 50,
        title:
          "ca. zwei gute Schnapsgläser reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 50,
        max: 60,
        title:
          "ca. drei knappe Schnapsgläser reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 60,
        max: 70,
        title: "ca. 1/4 Glas reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 70,
        max: 80,
        title: "ca. eine halbe Tasse reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 80,
        max: 90,
        title: "ca. drei große Schlücke reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 90,
        max: 100,
        title:
          "ca. fünf knappe Schnapsgläser reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 100,
        max: 110,
        title:
          "ca. fünf gute Schnapsgläser reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 110,
        max: 120,
        title: "ca. vier große Schlücke reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 120,
        max: 130,
        title: "ca. 1/2 Glas reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 130,
        max: 140,
        title: "ca. eine knappe Tasse reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 140,
        max: 150,
        title: "ca. eine Tasse reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 150,
        max: 160,
        title: "ca. eine Tasse reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 160,
        max: 170,
        title: "ca. eine gute Tasse reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 170,
        max: 180,
        title: "ca. sechs Schlücke reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 180,
        max: 190,
        title:
          "ca. sechs große Schlücke reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 190,
        max: 200,
        title: "ca. ein kleines Glas reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 200,
        max: 210,
        title: "ca. ein kleines Glas reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 210,
        max: 220,
        title: "ca. 1 1/2 knappe Tassen reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 220,
        max: 230,
        title: "ca. 1 1/2 Tassen reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 230,
        max: 240,
        title: "ca. 1 1/2 gute Tassen reines Wasser einer frischen Kokosnuss",
      },
      {
        min: 240,
        max: 250,
        title: "ca. ein Glas reines Wasser einer frischen Kokosnuss",
      },
    ],

    kombucha: [],
    "matcha-grüntee": [],
    orangensaft: [
      { min: 0, max: 1, title: null },
      { min: 1, max: 1.5, title: null },
      { min: 1.5, max: 2, title: null },
      { min: 2, max: 3, title: null },
      {
        min: 3,
        max: 4,
        title: "ca. ein Spritzer frisch gepresster Orangensaft",
      },
      {
        min: 4,
        max: 6,
        title: "ca. zwei Spritzer frisch gepresster Orangensaft",
      },
      { min: 6, max: 8, title: "ca. ein Schuss frisch gepresster Orangensaft" },
      {
        min: 8,
        max: 12,
        title: "ca. drei-vier Spritzer frisch gepresster Orangensaft",
      },
      {
        min: 12,
        max: 16,
        title: "ca. fünf Spritzer frisch gepresster Orangensaft",
      },
      {
        min: 16,
        max: 20,
        title: "frisch gepresster Saft aus ca. 1/4 kleinen Orange",
      },
      {
        min: 20,
        max: 26,
        title: "ca. ein gutes Schnapsglas frisch gepresster Orangensaft",
      },
      {
        min: 26,
        max: 33,
        title: "frisch gepresster Saft aus ca. 1/4 mittelgroßen Orange",
      },
      {
        min: 33,
        max: 40,
        title: "frisch gepresster Saft aus ca. 1/2 kleinen Orange",
      },
      {
        min: 40,
        max: 50,
        title: "ca. zwei gute Schnapsgläser frisch gepresster Orangensaft",
      },
      {
        min: 50,
        max: 60,
        title: "frisch gepresster Saft aus ca. 1/2 mittelgroßen Orange",
      },
      { min: 60, max: 70, title: "ca. 1/4 Glas frisch gepresster Orangensaft" },
      {
        min: 70,
        max: 80,
        title: "frisch gepresster Saft aus ca. einer kleinen Orange",
      },
      {
        min: 80,
        max: 90,
        title: "ca. drei große Schlücke frisch gepresster Orangensaft",
      },
      {
        min: 90,
        max: 100,
        title: "ca. fünf knappe Schnapsgläser frisch gepresster Orangensaft",
      },
      {
        min: 100,
        max: 110,
        title: "ca. fünf gute Schnapsgläser frisch gepresster Orangensaft",
      },
      {
        min: 110,
        max: 120,
        title: "frisch gepresster Saft aus ca. 1 1/2 kleinen Orangen",
      },
      {
        min: 120,
        max: 130,
        title: "ca. 1/2 Glas frisch gepresster Orangensaft",
      },
      {
        min: 130,
        max: 140,
        title: "ca. eine knappe Tasse frisch gepresster Orangensaft",
      },
      {
        min: 140,
        max: 150,
        title: "frisch gepresster Saft aus ca. einer großen Orange",
      },
      {
        min: 150,
        max: 160,
        title: "frisch gepresster Saft aus ca. zwei kleinen Orangen",
      },
      {
        min: 160,
        max: 170,
        title: "frisch gepresster Saft aus ca. 1 1/2 mittelgroßen Orangen",
      },
      {
        min: 170,
        max: 180,
        title: "ca. sechs Schlücke frisch gepresster Orangensaft",
      },
      {
        min: 180,
        max: 190,
        title: "ca. sechs große Schlücke frisch gepresster Orangensaft",
      },
      {
        min: 190,
        max: 200,
        title: "ca. ein kleines Glas frisch gepresster Orangensaft",
      },
      {
        min: 200,
        max: 210,
        title: "ca. ein kleines Glas frisch gepresster Orangensaft",
      },
      {
        min: 210,
        max: 220,
        title: "frisch gepresster Saft aus ca. zwei mittelgroßen Orangen",
      },
      {
        min: 220,
        max: 230,
        title: "frisch gepresster Saft aus ca. drei kleinen Orangen",
      },
      {
        min: 230,
        max: 240,
        title: "frisch gepresster Saft aus ca. drei kleinen Orangen",
      },
      {
        min: 240,
        max: 250,
        title:
          "frisch gepresster Saft aus ca. zwei mittelgroßen-großen Orangen",
      },
    ],
    quellwasser: [
      { min: 0, max: 1, title: null },
      { min: 1, max: 1.5, title: null },
      { min: 1.5, max: 2, title: null },
      { min: 2, max: 3, title: null },
      { min: 3, max: 4, title: "ca. ein Spritzer weiches Quellwasser" },
      { min: 4, max: 6, title: "ca. zwei Spritzer weiches Quellwasser" },
      { min: 6, max: 8, title: "ca. ein Schuss weiches Quellwasser" },
      { min: 8, max: 12, title: "ca. drei-vier Spritzer weiches Quellwasser" },
      { min: 12, max: 16, title: "ca. fünf Spritzer weiches Quellwasser" },
      {
        min: 16,
        max: 20,
        title: "ca. ein ordentlicher Schuss weiches Quellwasser",
      },
      {
        min: 20,
        max: 26,
        title: "ca. ein gutes Schnapsglas weiches Quellwasser",
      },
      { min: 26, max: 33, title: "ca. ein großer Schluck weiches Quellwasser" },
      {
        min: 33,
        max: 40,
        title: "ca. zwei knappe Schnapsgläser weiches Quellwasser",
      },
      {
        min: 40,
        max: 50,
        title: "ca. zwei gute Schnapsgläser weiches Quellwasser",
      },
      {
        min: 50,
        max: 60,
        title: "ca. drei knappe Schnapsgläser weiches Quellwasser",
      },
      { min: 60, max: 70, title: "ca. 1/4 Glas weiches Quellwasser" },
      { min: 70, max: 80, title: "ca. eine halbe Tasse weiches Quellwasser" },
      {
        min: 80,
        max: 90,
        title: "ca. drei große Schlücke weiches Quellwasser",
      },
      {
        min: 90,
        max: 100,
        title: "ca. fünf knappe Schnapsgläser weiches Quellwasser",
      },
      {
        min: 100,
        max: 110,
        title: "ca. fünf gute Schnapsgläser weiches Quellwasser",
      },
      {
        min: 110,
        max: 120,
        title: "ca. vier große Schlücke weiches Quellwasser",
      },
      { min: 120, max: 130, title: "ca. 1/2 Glas weiches Quellwasser" },
      {
        min: 130,
        max: 140,
        title: "ca. eine knappe Tasse weiches Quellwasser",
      },
      { min: 140, max: 150, title: "ca. eine Tasse weiches Quellwasser" },
      { min: 150, max: 160, title: "ca. eine Tasse weiches Quellwasser" },
      { min: 160, max: 170, title: "ca. eine gute Tasse weiches Quellwasser" },
      { min: 170, max: 180, title: "ca. sechs Schlücke weiches Quellwasser" },
      {
        min: 180,
        max: 190,
        title: "ca. sechs große Schlücke weiches Quellwasser",
      },
      { min: 190, max: 200, title: "ca. ein kleines Glas weiches Quellwasser" },
      { min: 200, max: 210, title: "ca. ein kleines Glas weiches Quellwasser" },
      {
        min: 210,
        max: 220,
        title: "ca. 1 1/2 knappe Tassen weiches Quellwasser",
      },
      { min: 220, max: 230, title: "ca. 1 1/2 Tassen weiches Quellwasser" },
      {
        min: 230,
        max: 240,
        title: "ca. 1 1/2 gute Tassen weiches Quellwasser",
      },
      { min: 240, max: 250, title: "ca. ein Glas weiches Quellwasser" },
    ],
  });
  const getTooltip = (data, e) => {
    let obj = tooltipData[data?.name?.toLowerCase()]?.find(
      (d) => data?.mlValue > d.min && data?.mlValue <= d.max
    );

    // console.log("data From Hook ", data?.name);

    var tooltipCont = document.getElementById(e.target.id);
    var tooltipDiv = document.createElement("div");
    tooltipDiv.setAttribute("id", "tooltipDiv");
    tooltipDiv.classList.add("tooltip-content");
    if (obj?.title) {
      tooltipDiv.textContent = obj?.title;
      tooltipDiv.style.display = "block";
      tooltipCont.appendChild(tooltipDiv);
    }

    tooltipCont.addEventListener("mouseout", function () {
      tooltipDiv.remove();
    });
    // return obj?.title;
  };
  return {
    getTooltip,
  };
};

export default useIngTooltip;
