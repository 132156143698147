import React from "react";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const Analytics = () => {
  let location = useLocation();
  // Multiple Trackers
  ReactGA.initialize(
    [
      {
        trackingId: "G-L6Q6F1V68P",
        gaOptions: {
          name: "Indivit",
        },
      },
      {
        trackingId: "G-1M0EJW71XL",
        gaOptions: { name: "Tauqeer" },
      },
    ],
    { debug: true, alwaysSendToDefaultTracker: false }
  );
  useEffect(() => {
    ReactGA.ga("Indivit.set", "page", location.pathname + location.search);
    ReactGA.ga("Indivit.send", "pageview1");
    ReactGA.ga("Tauqeer.set", "page", location.pathname + location.search);
    ReactGA.ga("Tauqeer.send", "pageview2");
  }, [location]);

  // Single Tracker

  // ReactGA.initialize("G-1M0EJW71XL", {
  //   debug: true,
  //   // titleCase: false,
  // });
  useEffect(() => {
    ReactGA.event({
      category: "Button Click",
      action: "Test Clicked",
      label: "Homepage Button",
    });
    ReactGA.pageview(location.pathname);
    ReactGA.set({ userId: "123" }, ["Indivit", "Tauqeer"]);
    // ReactGA.initialize("Your-GA-Measurement-ID");
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname + location.search);
    ReactGA.send("Test", "Test Tracker");
    ReactGA.event({
      category: "User",
      action: "Clicked the Button",
    });
    console.log("pathname ", location.pathname);
    console.log("search ", location.search);
    console.log("location ", location);
  }, [location]);


  return null;
};

export default Analytics;
