import React from "react";
import ProgressBarElement from "../../components/common/ProgressBarElement";
import { getSmoothieRecipeLevel } from "../../services/Ingredients";
import { useQuery } from "@tanstack/react-query";
import {
  formatToGerman2,
  formatToGerman1,
} from "../../services/utils/germanFormat";

export default function TasteSection({ data, basiColor, suggest }) {
  // console.log("Data From Taste Section", data);
  const {
    isLoading: SmoothieRecipeLevelLoading,
    error: SmoothieRecipeLevelError,
    data: SmoothieRecipeLevelData,
  } = useQuery({
    queryKey: ["SmoothieRecipeLevelListing"],
    queryFn: getSmoothieRecipeLevel,
  });
  let Ingredientlevel = SmoothieRecipeLevelData?.data?.data;

  const getTitle = (name, tasteType) => {
    let tasteObj = Ingredientlevel?.find(
      (d) => d?.recipe_tastes?.toLowerCase() == tasteType
    );
    return data?.[name] < tasteObj?.much_less
      ? "Sehr wenig"
      : data?.[name] < tasteObj?.less
      ? "Etwas weniger"
      : data?.[name] < tasteObj?.medium
      ? "Genau richtig"
      : data?.[name] < tasteObj?.much
      ? "Etwas stärker"
      : "Sehr stark";
  };
  const getColor = (name, tasteType) => {
    let tasteObj = Ingredientlevel?.find(
      (d) => d?.recipe_tastes?.toLowerCase() == tasteType
    );
    return data?.[name] < tasteObj?.much_less
      ? tasteObj?.much_less_color
      : data?.[name] < tasteObj?.less
      ? tasteObj?.less_color
      : data?.[name] < tasteObj?.medium
      ? tasteObj?.medium_color
      : data?.[name] < tasteObj?.much
      ? tasteObj?.high_color
      : tasteObj?.much_more_color;
  };
  let sweetColor = getColor("sweet", "sweet");
  let SourColor = getColor("sauer", "sour");
  let bitterColor = getColor("bitter", "bitter");
  let intensityColor = getColor("intensity", "intensity");
  let creamyColor = getColor("creaming", "creamy");
  let sweetTitle = getTitle("sweet", "sweet");
  let sourTitle = getTitle("sauer", "sour");
  let bitterTitle = getTitle("bitter", "bitter");
  let intensityTitle = getTitle("intensity", "intensity");
  let creamyTitle = getTitle("creaming", "creamy");

  return (
    <div>
      <ProgressBarElement
        name="süß"
        value={data?.sweet == 0 ? 0.1 * 20 : data?.sweet * 20}
        label={`${formatToGerman1(
          data?.sweet > 5.0 ? 5.0 : data?.sweet
        )} / ${formatToGerman1(5.0)}`}
        // if Value Exceeds Convert it to 5.0 Ref 2024-01-27 page 4
        color={basiColor ? basiColor : sweetColor ? sweetColor : "green"}
        suggest={suggest == "no" ? suggest : ""}
        ingredient={true}
        tasteTool={true}
        title={sweetTitle}
      />
      <ProgressBarElement
        name="sauer"
        value={data?.sauer == 0 ? 0.1 * 20 : data?.sauer * 20}
        label={`${formatToGerman1(
          data?.sauer > 5.0 ? 5.0 : data?.sauer
        )} / ${formatToGerman1(5.0)}`}
        color={basiColor ? basiColor : SourColor ? SourColor : "green"}
        suggest={suggest == "no" ? suggest : ""}
        ingredient={true}
        tasteTool={true}
        title={sourTitle}
      />
      <ProgressBarElement
        name="bitter"
        value={data?.bitter == 0 ? 0.1 * 20 : data?.bitter * 20}
        label={`${formatToGerman1(
          data?.bitter > 5.0 ? 5.0 : data?.bitter
        )} / ${formatToGerman1(5.0)}`}
        color={basiColor ? basiColor : bitterColor ? bitterColor : "green"}
        suggest={suggest == "no" ? suggest : ""}
        ingredient={true}
        tasteTool={true}
        title={bitterTitle}
      />
      <ProgressBarElement
        name="intensiv"
        value={data?.intensity == 0 ? 0.1 * 20 : data?.intensity * 20}
        label={`${formatToGerman1(
          data?.intensity > 5.0 ? 5.0 : data?.intensity
        )} / ${formatToGerman1(5.0)}`}
        color={
          basiColor ? basiColor : intensityColor ? intensityColor : "green"
        }
        suggest={suggest == "no" ? suggest : ""}
        ingredient={true}
        tasteTool={true}
        title={intensityTitle}
      />
      <ProgressBarElement
        name="cremig"
        value={data?.creaming == 0 ? 0.1 * 20 : data?.creaming * 20}
        label={`${formatToGerman1(
          data?.creaming > 5.0 ? 5.0 : data?.creaming
        )} / ${formatToGerman1(5.0)}`}
        color={basiColor ? basiColor : creamyColor ? creamyColor : "green"}
        suggest={suggest == "no" ? suggest : ""}
        ingredient={true}
        tasteTool={true}
        title={creamyTitle}
      />
    </div>
  );
}
