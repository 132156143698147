import React from "react";
import RecipeBanner from "./WhyIndivit/RecipeBanner";
import { BoxCard, RecipeCard, SkeltonCard } from "../components/Cards";
import SustainableSection from "../components/section/SustainableSection";
import VisionRecipeSection from "../components/section/VisionRecipeSection";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import rightIcon from "../assets/img/left.png";
import leftIcon from "../assets/img/right.png";
import { getLimitedSmoothies, getSmoothies } from "../services/Smoothie";
import { getLimitedBoxes } from "../services/SmoothieBox";
import { useQuery } from "@tanstack/react-query";
import HomeCarousel from "../components/HomeCarousel";
import { Helmet } from "react-helmet";
import { getWebSettings } from "../services/Settings";
import { useSelector } from "react-redux";
import Testimonials from "../components/section/Testimonials";
import { useLocation } from "react-router-dom";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 700 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 700, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="carousel-button-group position-absolute  w-100 ">
      <button
        className=" btn position-absolute start-0 px-0 shadow-none"
        onClick={() => previous()}
      >
        <img
          height="114px"
          width="62px"
          className="img-fluid "
          src={leftIcon}
        />
      </button>
      <button
        className="btn position-absolute end-0 px-0 shadow-none"
        onClick={() => next()}
      >
        <img
          height="114px"
          width="62px"
          className="img-fluid "
          src={rightIcon}
        />
      </button>
    </div>
  );
};
export default function Home() {
  const { pathname } = useLocation();
  const pageTitle = useSelector((state) => state.settings?.name);

  const {
    isLoading: smoothieLoading,
    error: smoothieError,
    data: smoothieData,
  } = useQuery({
    queryKey: ["limitedAdminSmoothies"],
    queryFn: getLimitedSmoothies,
    // get Admin Smoothies Limit
  });
  const {
    isLoading: boxLoading,
    error: boxError,
    data: boxData,
  } = useQuery({
    queryKey: ["limitedboxListing"],
    queryFn: getLimitedBoxes,
  });

  const smoothies = smoothieData?.data?.smoothies || [];
  const boxes = boxData?.data?.data || [];
  console.log("Page Title ", pageTitle);
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
      </Helmet>
      {pageTitle && (
        <Helmet>{pageTitle && <title> {`${pageTitle}`} </title>}</Helmet>
      )}
      {/* {(smoothieLoading || boxLoading) && <Loader />} */}
      {<HomeCarousel />}

      {/* <ReadyBoxesSection /> */}
      <section id="flx-products-box">
        <div className="container">
          <h2
            className="text-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Deine Smoothie² Basis
          </h2>
          <p
            className="pb-5 text-center px-md-5"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            Die Smoothie² Basis Pakete bekommst du mit 6, 12, 18 oder 24
            Flaschen frisch zubereitet. Alle Smoothies kommen dabei fertig
            gemixt und gekühlt zum Wunschtermin zu dir und sind mindestens 4
            Wochen haltbar. Jede einzelne Flasche kannst du auch ganz einfach in
            unserem Smoothie Konfigurator an deinen Geschmack und deine
            Bedürfnisse anpassen. Du kannst unsere Smoothie² Basis natürlich als
            100% pure Frucht genießen, oder du verdünnst sie zu einem
            trinkfertigen Smoothie, z.B. mit einem unserer Liquids
          </p>
          <div className="row spce1">
            {boxLoading || boxes?.length == 0
              ? Array.from(Array(8))?.map((box, index) => {
                  return (
                    <div
                      key={index}
                      className="col-xs-12 col-sm-6 col-md-3 py-3 col-lg-3  "
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <SkeltonCard />
                    </div>
                  );
                })
              : boxes?.map((box, index) => {
                  return (
                    <div
                      key={index}
                      className="col-xs-12 col-sm-6 col-md-3 col-lg-3 hsn-box-bg my-3"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <BoxCard data={box} />
                    </div>
                  );
                })}
          </div>
        </div>
      </section>
      {/* Ready Boxes Section END */}

      <RecipeBanner />

      {/* <!-- slider owl-carousel start --> */}
      <section id="flx-owl-carousel">
        <div className="container-fluid px-0">
          <div className=" container pb-5 text-center">
            <h2 data-aos="fade-up" data-aos-duration="1000">
              Unsere Smoothie Rezepte
            </h2>
            <p data-aos="fade-up" data-aos-duration="1500">
              Wir haben vielfältige Vorschläge für ausgewogene Smoothie Rezepte
              für dich erstellt. Lass dich gerne von unseren Kreationen
              inspirieren und probier das eine oder andere Rezept einfach einmal
              aus. Du kannst jedes Rezept auch direkt in unserem Online Smoothie
              Mixer anpassen, ändern und verfeinern
            </p>
          </div>
          <div className=" m-auto position-relative ">
            <Carousel
              responsive={responsive}
              itemclassName="px-5"
              autoPlay={true}
              arrows={false}
              renderButtonGroupOutside={true}
              customButtonGroup={<ButtonGroup />}
              infinite={true}
            >
              {smoothieLoading || smoothies?.length == 0
                ? Array.from(Array(8))?.map((box, index) => {
                    return (
                      <div key={index} className="px-md-5">
                        <SkeltonCard />
                      </div>
                    );
                  })
                : smoothies?.map((smooth, index) => {
                    return (
                      <div key={index}>
                        <RecipeCard
                          isButton={false}
                          data={smooth}
                          action={`/recipedetail/${smooth?.unique_id}`}
                        />
                      </div>
                    );
                  })}
            </Carousel>

            {/* {[1, 2, 3, 4, 5, 6].map((prod, index) => {
              return <ProductCard />;
            })} */}
          </div>
          <div className="text-center container ">
            <hr />
            {/* <p>
              Still can't find the perfect recipe? Try our recipe finder to find
              the best tastiest recipes using only the Z
            </p> */}
          </div>
        </div>
      </section>
      {/* <!-- slider owl-carousel end --> */}

      <SustainableSection />

      {/* Testimonials Start Here */}

      <Testimonials />

      {/* <!------image and text section name vision & recipes start------> */}

      <VisionRecipeSection />

      {/* <!------image and text section name vision & recipes End------> */}
    </div>
  );
}

// in PHP we can assign values in both way assign by value and assign by reference. assign by value will not update original variable, while assign by refernce will update original variable as well
