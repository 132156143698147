import React from "react";
import VisionRecipeSection from "../../components/section/VisionRecipeSection";
import AboutDetails from "./AboutDetails";
import AboutRecipe from "../../components/AboutRecipe";
import { useLayoutEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { getAboutUsPage } from "../../services/StaticPages";
import Loader from "../../components/common/Loader";
import AboutJSON from "./JSONData.json";
import { Helmet } from "react-helmet";
import HeroBanner from "../../components/common/HeroBanner";
import { useLocation } from "react-router-dom";

export default function About() {
  const { pathname } = useLocation();

  // console.log("About JSON ", AboutJSON);
  useLayoutEffect(() => {
    window.scroll(0, 0);
  }, []);

  const {
    isLoading: aboutLoading,
    error: aboutError,
    data,
  } = useQuery({
    queryKey: ["aboutUsPage"],
    queryFn: getAboutUsPage,
  });
  let aboutData = data?.data?.data.length > 0 ? data?.data?.data[0] : {};
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
      </Helmet>

      {aboutData && (
        <Helmet>
          {aboutData?.main_title && (
            <title> {`Indivit | Uber uns | ${aboutData?.main_title}`} </title>
          )}
          {aboutData?.main_description && (
            <meta name="description" content={aboutData?.main_description} />
          )}
        </Helmet>
      )}
      {aboutLoading && <Loader />}
      <HeroBanner
        data={{
          title: aboutData?.main_title,
          description: aboutData?.main_description,
        }}
        bgImg=" !tw-bg-about"
      />

      <AboutDetails
        data={{
          title: aboutData?.section_heading,
          description: aboutData?.section_description,
        }}
      />
      <AboutRecipe data={AboutJSON?.about} color="#F8F9FA" />
      <VisionRecipeSection />
    </div>
  );
}
