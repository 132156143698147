import { GET } from "./Adapter/base";

export const getIngredients = () => {
  return GET(`get_ingredient`);
};
export const getSmoothieRecipeLevel = () => {
  return GET(`smoothie_recipe_level`);
};
export const getIngredientById = (id) => {
  return GET(`get_ingredient/${id}`);
};
