import React, { useLayoutEffect } from "react";
import session from "../../services/utils/session";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import Loader from "../../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../redux/account";
import { wishlistResetAction } from "../../redux/wishlist";

import { useQuery } from "@tanstack/react-query";
import { getOrders } from "../../services/Orders";
import { Helmet } from "react-helmet";
import HeroBanner from "../../components/common/HeroBanner";

export default function Profile() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.account.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    setLoading(true);
    session.clear();
    dispatch(logoutAction());
    dispatch(wishlistResetAction());
    setLoading(false);
    navigate("/login");
  };

  const {
    isLoading: orderLoading,
    error: orderError,
    data: orderData,
  } = useQuery({
    queryKey: ["orderListing"],
    queryFn: getOrders,
  });
  const orders = orderData?.data?.data;
  // let page = searchParams.get("page");
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
        <title> {`Indivit | Profile`} </title>
      </Helmet>
      {orderLoading && loading && <Loader />}
      <HeroBanner
        data={{
          title: "Wilkommen",
          title2: user?.name,
        }}
        bgImg=" !tw-bg-faq"
      />

      <section>
        <div class="container py-5">
          <div class="row">
            <div class="col">
              <nav
                aria-label="breadcrumb"
                class="bg-white shadow-sm rounded-3 p-3 mb-4"
              >
                <ol class="breadcrumb mb-0">
                  <li class="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="#">User</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    User Profile
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4 h-100">
              <div class="card mb-4 h-100 border-0 shadow-sm ">
                <div class="card-body text-center">
                  <img
                    src={`https://ui-avatars.com/api/?background=random&size=150&name=${user?.name}`}
                    alt="avatar"
                    class="rounded-circle img-fluid"
                    style={{ width: "150px" }}
                  />
                  <h5 class="my-3"> {user?.name}</h5>
                  {/* <p class="text-muted mb-1">Full Stack Developer</p> */}
                  {/* <p class="text-muted mb-4">Bay Area, San Francisco, CA</p> */}
                  {/* <div class="d-flex justify-content-center mb-2">
                    <button type="button" class="btn btn-primary">
                      Follow
                    </button>
                    <button type="button" class="btn btn-outline-primary ms-1">
                      Message
                    </button>
                  </div> */}
                </div>
              </div>
              {/* <div class="card mb-4 mb-lg-0">
                <div class="card-body p-0">
                  <ul class="list-group list-group-flush rounded-3">
                    <li class="list-group-item d-flex justify-content-between align-items-center p-3">
                      <i class="fas fa-globe fa-lg text-warning"></i>
                      <p class="mb-0">https://mdbootstrap.com</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center p-3">
                      <i
                        class="fab fa-github fa-lg"
                        style={{ color: "#333333" }}
                      ></i>
                      <p class="mb-0">mdbootstrap</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center p-3">
                      <i
                        class="fab fa-twitter fa-lg"
                        style={{ color: "#55acee" }}
                      ></i>
                      <p class="mb-0">@mdbootstrap</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center p-3">
                      <i
                        class="fab fa-instagram fa-lg"
                        style={{ color: "#ac2bac" }}
                      ></i>
                      <p class="mb-0">mdbootstrap</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center p-3">
                      <i
                        class="fab fa-facebook-f fa-lg"
                        style={{ color: "#3b5998" }}
                      ></i>
                      <p class="mb-0">mdbootstrap</p>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div class="col-lg-8">
              <div class="card mb-4 border-0 shadow-sm">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">Name</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0"> {user?.name} </p>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">E-Mail</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0"> {user?.email} </p>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">Telefon</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0"> To be Added .... </p>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">Mobil</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0"> To be Added .... </p>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">Adresse</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0"> To be Added .... </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="row">
                <div class="col-md-6">
                  <div class="card mb-4 mb-md-0">
                    <div class="card-body">
                      <p class="mb-4">
                        <span class="text-primary font-italic me-1">
                          assigment
                        </span>{" "}
                        Project Status
                      </p>
                      <p class="mb-1" style={{ fontSize: ".77rem" }}>
                        Web Design
                      </p>
                      <div class="progress rounded" style={{ height: "5px" }}>
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "80%" }}
                          aria-valuenow="80"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <p class="mt-4 mb-1" style={{ fontSize: ".77rem" }}>
                        Website Markup
                      </p>
                      <div class="progress rounded" style={{ height: "5px" }}>
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "72%" }}
                          aria-valuenow="72"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <p class="mt-4 mb-1" style={{ fontSize: ".77rem" }}>
                        One Page
                      </p>
                      <div class="progress rounded" style={{ height: "5px" }}>
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "89%" }}
                          aria-valuenow="89"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <p class="mt-4 mb-1" style={{ fontSize: ".77rem" }}>
                        Mobile Template
                      </p>
                      <div class="progress rounded" style={{ height: "5px" }}>
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "55%" }}
                          aria-valuenow="55"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <p class="mt-4 mb-1" style={{ fontSize: ".77rem" }}>
                        Backend API
                      </p>
                      <div
                        class="progress rounded mb-2"
                        style={{ height: "5px" }}
                      >
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "66%" }}
                          aria-valuenow="66"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card mb-4 mb-md-0">
                    <div class="card-body">
                      <p class="mb-4">
                        <span class="text-primary font-italic me-1">
                          assigment
                        </span>{" "}
                        Project Status
                      </p>
                      <p class="mb-1" style={{ fontSize: ".77rem" }}>
                        Web Design
                      </p>
                      <div class="progress rounded" style={{ height: "5px" }}>
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "80%" }}
                          aria-valuenow="80"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <p class="mt-4 mb-1" style={{ fontSize: ".77rem" }}>
                        Website Markup
                      </p>
                      <div class="progress rounded" style={{ height: "5px" }}>
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "72%" }}
                          aria-valuenow="72"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <p class="mt-4 mb-1" style={{ fontSize: ".77rem" }}>
                        One Page
                      </p>
                      <div class="progress rounded" style={{ height: "5px" }}>
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "89%" }}
                          aria-valuenow="89"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <p class="mt-4 mb-1" style={{ fontSize: ".77rem" }}>
                        Mobile Template
                      </p>
                      <div class="progress rounded" style={{ height: "5px" }}>
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "55%" }}
                          aria-valuenow="55"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <p class="mt-4 mb-1" style={{ fontSize: ".77rem" }}>
                        Backend API
                      </p>
                      <div
                        class="progress rounded mb-2"
                        style={{ height: "5px" }}
                      >
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "66%" }}
                          aria-valuenow="66"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
