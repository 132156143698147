import { GET, POST } from "./Adapter/base";

export const getBoxes = () => {
  return GET(`smoothie_box_description`);
};
export const getLimitedBoxes = () => {
  return GET(`smoothie_box_description_limit`);
};
export const getBoxById = (id) => {
  return GET(`smoothie_box_description/${id}`);
};
export const addBox = (data) => {
  return POST(`smoothie_box_description`, data);
};
export const getBoxSize = () => {
  return GET(`get_smoothie_box_size`);
};
export const deleteBox = (id) => {
  return POST(`del_smoothie_box/${id}`);
};
export const getBoxBenefits = () => {
  return GET(`benefits`);
};
