import React from "react";

export default function ConfirmModal({
  action,
  className,
  closeRef,
  isLoading,
  innerHtml,
}) {
  return (
    <>
      <button
        className={`btn btn-outline-danger p-10-25 mx-2 rounded-8 shadow-none ${className} `}
        data-bs-toggle="modal"
        data-bs-target="#confirmModal"
        type="button"
      >
        Löschen
      </button>

      {/* <!-- Confirm Popup Modal --> */}
      <div
        class="modal fade"
        id="confirmModal"
        tabIndex="-1"
        aria-labelledby="confirmModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="confirmModalLabel">
                Weg-ist-weg
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
              ></button>
            </div>
            <div class="modal-body">
              {innerHtml
                ? innerHtml
                : `Bist du dir sicher, dass du deine Box löschen möchtest?`}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn" data-bs-dismiss="modal">
                Abbrechen
              </button>
              <button
                id="confirmAction"
                type="button"
                class="btn btn-danger shadow-none"
                onClick={action}
              >
                {isLoading ? (
                  <>
                    <span
                      class="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Entfernen...
                  </>
                ) : (
                  "Ja, löschen"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
