import { GET, POST } from "./Adapter/base";

export const addOrderFeedback = (data) => {
  return POST(`order_rating`, data);
};
export const addSmoothieFeedback = (data) => {
  return POST(`smoothie_rating`, data);
};

export const getProductByProdID = (id) => {
  return GET(`smoothie_rating?id=${id}`);
};
export const postFeedback = (data) => {
  return POST(`smoothie_rating`, data);
};

export const getTestimonials = () => {
  return GET(`testimonials`);
};

// smoothie_rating;
