import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import envelopIcon from "../../assets/img/reset-img.png";

export default function GetReset() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section id="flx-login">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-6">
              <div className="card" style={{ borderRadius: "3rem" }}>
                <div className="card-body p-5">
                  <div className="mb-md-5 ">
                    <div className="text-center">
                      <img
                        src={envelopIcon}
                        alt=""
                        className="img-fluid pb-3"
                      />
                    </div>
                    <h3 className="mb-2 text-center pb-2">
                      {/* Reset Password! */}
                      Passwort zurücksetzten
                    </h3>
                    <p className="text-center pb-2">
                      Bitte klicke auf den in der E-Mail enthaltenen Link, um
                      Dein Passwort zurückzusetzen. Wir haben eine E-Mail von
                      der folgenden Adresse gesendet:
                      {/* Please go to the link included in the email to reset your
                      password We have sent an email from the following email
                      address: */}
                    </p>
                    <h5 className="text-center text-theme-success">
                      smoothie@indivit.de
                    </h5>
                  </div>
                  <p className="text-center">
                    <Link to="login" className="text-theme-success">
                      &nbsp; &nbsp;Zurück zur Anmeldung
                      {/* Back to Login */}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
