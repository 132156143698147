// import "bootstrap/dist/css/bootstrap.css";
import "react-multi-carousel/lib/styles.css";
import "bootstrap/dist/js/bootstrap.js";
import "aos/dist/aos.css";
import "aos/dist/aos.js";
import "./assets/css/style.css";
import "./assets/css/countdown.css";
import "./assets/css/bootstrap.css";
import "./assets/fontawesome/css/all.css";
import "./App.css";
import Routes from "./Routes";
import { useEffect, useState } from "react";
import AOS from "aos";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CartProvider } from "react-use-cart";
import Cookies from "./components/cookies/Cookies";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000, // 5 Minuts
    },
  },
});

function App() {
  useEffect(() => {
    if (AOS) {
      AOS.init();
      AOS.refresh();
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <CartProvider>
        <Cookies />
        <Routes />
        <Analytics />
        <SpeedInsights />
      </CartProvider>
    </QueryClientProvider>
  );
}

export default App;
