import React from "react";
import productImg from "../../assets/img/Sustainable_smoothie.png";
export default function SustainabilityDetails() {
  return (
    <div>
      {/* <!-- about section --> */}
      <section id="flx-about" className="!tw-pt-16 ">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 flx-about-col pe-5">
              <div className="text-left">
                <div className="col-6 float-end flx-forever-object">
                  <div className="disk-container d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <h2 className="hsn-smoothies fs-2 fw-bold mb-1 lh-1">
                        Indivit
                      </h2>
                      <p className="fs-6 mb-1 lh-1">
                        Bio-Zutaten <br/> Recycling <br/> Ressourcen <br/> Verschwendung
                      </p>
                    </div>
                  </div>
                  <div className="text-left flx-img-position">
                    <img src={productImg} className="img-fluid z-3" alt="" />
                  </div>
                </div>
                <h2>
                  Mit indivit Smoothies:{" "}
                  <span className="hsn-smoothies">
                    &nbsp;Schluck für Schluck zur Nachhaltigkeit
                  </span>
                </h2>{" "}
                <p>
                  Wenn es um Smoothies geht, dann spielt Nachhaltigkeit in allen
                  Stufen der Wertschöpfung eine Rolle. Wir haben unsere Prozesse
                  entsprechend beleuchtet und arbeiten weiter an einer
                  Minimierung unseres ökologischen Fußabdrucks.
                </p>
                <p>
                  <span className="hsn-smoothies">
                    <b>
                      Umweltschutz durch reine Verwendung von Zutaten, nach den
                      Richtlinien des ökologischen Landbaus:
                    </b>
                  </span>
                  <br />
                  Die verwendeten Biolebensmittel tragen, nach Angaben des
                  Umwelt Bundesamtes, zum Umweltschutz bei. Dies gelingt, indem
                  sie Umweltbelastungen reduzieren, die sonst mit der
                  konventionellen Landwirtschaft auftreten. Dazu zählt der
                  vollständige Verzicht auf chemisch -synthetische
                  Pflanzenschutzmittel und auf leicht lösliche mineralische
                  Düngemittel. Leider sind diese umweltschonende Anbaumethoden
                  in der Regel mit Mehrkosten verbunden, sodass Biolebensmittel
                  meist teurer gegenüber vergleichbaren konventionellen
                  Lebensmitteln sind. Dabei ist jedoch zu beachten, dass
                  konventionelle Lebensmittel auch deshalb so günstig sind, weil
                  die Kosten für die damit verbundenen Umweltschäden an die
                  Allgemeinheit und zukünftige Generationen weitergegeben
                  werden. Daher haben wir uns klar entschieden – wir möchten
                  unseren Beitrag zu einer lebenswerten Umwelt leisten und
                  verwenden ausschließlich Zutaten aus ökologischem Anbau.
                  Zusätzlich sind wir uns bewusst, dass einige unserer Zutaten
                  auch aus weiter entfernteren Ländern zu uns reisen müssen.
                  Daher informieren wir mit transparenten Angaben über die
                  jeweiligen Herkunftsländer sowie über den durchschnittlichen
                  CO2 -Fußabdrucks aller Zutaten. So ist jeder Kunde in der
                  Lage, seinen individuellen Smoothie so zu erstellen, dass sein
                  CO2 -Budget minimal belastet wird. Darüber hinaus verzichten
                  wir generell auf die Nutzung von importierter Flugware .
                </p>
                <p>
                  <span className="hsn-smoothies">
                    <b>
                      Beitrag zu einem nachhaltigem Kreislauf durch maximalen
                      Einsatz von recyceltem und recyclingfähigem Material:{" "}
                    </b>
                  </span>
                  <br />
                  Natürlich benötigt ein Smoothie eine Verpackung. Wir haben uns
                  jedoch dazu entschieden, die genutzten Verpackungen auf ein
                  Minimum zu reduzieren und alle eingesetzten Materialien
                  entlang ökologischer Kriterien auszuwählen. Dies ist unser
                  Beitrag zu einem nachhaltigem Ressourcenkreislauf. <br /> Die
                  Flasche unseres Smoothies besteht zu 100% aus recyceltem PET.
                  Dies bedeutet, dass die Herstellung einer Flasche lediglich
                  ca. 10% der Energie im Vergleich zu herkömmlichen PET-Flaschen
                  benötigt. Durch den Anschluss an das Einweg-Pfandsystem ist
                  die Weiterverwendung des Flaschenmaterials im
                  Recyclingkreislauf sichergestellt. Wir haben uns übrigens
                  bewusst gegen die Verwendung von Glasflaschen entschieden, da
                  diese im direkten Öko-Vergleich schlechter abschneiden, sobald
                  sie weiter als ca. 200km transportiert werden müssen und
                  weniger als ca. 15 mal wieder verwendet werden.
                  <br /> Für den Versand unserer Smoothies kommt ausschließlich
                  recyclingfähiges Verpackungsmaterial zum Einsatz. Wir waren
                  nicht glücklich über die bestehenden Verpackungsangebote und
                  haben deshalb ganz einfach unsere eigene Verpackung für den
                  gekühlten Versand unserer Smoothies entwickelt. Die gesamte
                  Verpackung besteht dabei zu ca. 50% aus recyceltem Altpapier.
                  Dadurch wird unsere Umwelt weniger stark belastet, denn – nach
                  Angaben des Umwelt Bundesamtes – benötigt die Produktion von
                  Recyclingpapier nur etwa 50% an Energie und nur rund 33% der
                  Wassermenge. Außerdem werden pro Kilogramm recyceltem Papier
                  bis zu 2,2 Kilogramm Holz eingespart. Letztendlich kann unsere
                  Versandverpackung vollständig im Altpapier entsorgt werden und
                  wird schon bald wieder in neuen Produkten Verwendung finden.
                </p>
                <p>
                  <span className="hsn-smoothies">
                    <b>
                      Wir wollen Ressourcen sinnvoll nutzen, um für alle das
                      bestmögliche Ergebnis zu erzielen – in der höchsten
                      Qualität:
                    </b>
                  </span>{" "}
                  <br />
                  Für uns bedeutet ein nachhaltiger Umgang mit unserer Umgebung,
                  dass wir notwendige Ressourcen generell sinnvoll einsetzen –
                  egal ob diese in Kilowattstunden (für Energie), in Litern
                  (z.B. für Wasser), in Kilogramm (z.B. für CO2), in Stunden
                  (für investierte Zeit) oder in Euro gemessen werden. Das
                  bedeutet, dass wir durchaus Ressourcen verbrauchen. Allerdings
                  haben wir uns zum Ziel gesetzt den Ressourceneinsatz, über den
                  gesamten Prozess zur Herstellung von vitalen Smoothies hinweg,
                  so sinnvoll wie möglich zu gestalten. So nutzen wir zum
                  Beispiel - aus unserer Sicht – das beste verfügbare Equipment
                  zur Herstellung unserer feinen Smoothies. Damit ersparen wir
                  vielen unserer Kunden sich selbst einen teuren Mixer
                  anzuschaffen, der womöglich im privaten Gebrauch nur eine
                  geringe Auslastung finden würde. Neben den somit optimal
                  genutzten Geräteressourcen macht es unsere zentrale Produktion
                  möglich - im Vergleich zum individuellen Privatgebrauch – auch
                  weitere Ressourcen zu sparen. Das bedeutet z.B. einen geringen
                  Verbrauch von Wasser und letztendlich auch einen optimalen
                  Einsatz einer weiteren wichtigen Ressource: Zeit. Als weitere,
                  beinahe schon selbstverständliche Maßnahme, versorgen wir
                  unseren Berliner Firmensitz ausschließlich mit Energie aus
                  erneuerbaren Quellen. Jeder sinnvolle Beitrag zu einem
                  verantwortungsbewussten Umgang mit unseren begrenzten
                  Ressourcen ist daher auch in Zukunft willkommen.
                </p>
                <p>
                  <span className="hsn-smoothies">
                    <b>
                      {" "}
                      Vermeidung von Abfall in allen Stufen der Wertschöpfung –
                      zu jedem Zeitpunkt:
                    </b>
                  </span>
                  <br />
                  Wir bestreben, bei allem was wir tun, möglichst wenig Abfall
                  zu produzieren und Verschwendungen auf ein Minimum zu
                  reduzieren. Das fängt bei unserem innovativen
                  Verpackungskonzept an und geht über den generell
                  verantwortungsbewussten Umgang mit Ressourcen hinaus. Leider
                  leben wir alle in einem Umfeld, in dem – nach Angaben der
                  Welthungerhilfe – weltweit ca. 17% aller Lebensmittel
                  ungenutzt in der Tonne landen. Für unseren Kontext ist dabei
                  besonders hervorzuheben die Lebensmittelverluste in der
                  Industrie (insbesondere durch Überproduktion), im Einzelhandel
                  (insbesondere, wenn Erzeugnisse liegen bleiben und entsorgt
                  werden müssen) und in der Gastronomie (insbesondere durch eine
                  unkalkulierbare Nachfrage). Die Produktion von Lebensmitteln,
                  die letzten Endes im Müll landen, verschwendet damit wichtige
                  Ressourcen wie Energie, Wasser und Ackerflächen, die in
                  anderen Ländern dringend benötigt werden. Um einen großen Teil
                  der Lebensmittelabfälle zu reduzieren, fordert das
                  Bundeszentrum für Ernährung deshalb die Einbindung aller
                  Akteure in der Lebensmittelversorgungskette. Genau an dieser
                  Stelle setzen wir an, indem wir Verschwendungen jeder Art
                  regelrecht den Kampf ansagen. Das bedeutet, wir haben uns
                  entschieden, unsere leckeren Smoothies nur auf konkrete
                  Bestellung hin zu produzieren. Damit binden wir unsere Kunden
                  direkt in den Produktionsprozess ein und senken die
                  Verschwendung durch z.B. Überproduktion oder Liegenbleiben
                  quasi auf Null. Ganz nebenbei heißt das übrigens auch, dass
                  jeder produzierte Smoothie so frisch wie möglich beim Kunden
                  landet – damit bleibt der Anteil der gesunden Vitalstoffe
                  besonders hoch. So sinnvoll kann die Reduktion von
                  Verschwendung nämlich auch sein.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- about section --> */}
    </div>
  );
}
