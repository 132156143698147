import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { getCookies } from "../../services/Settings";
import CookiesSettingModal from "./CookiesSettingModal";
import CookiesModal from "./CookiesModal";
import session from "../../services/utils/session";

export default function Cookies() {
  const [cookieModal, setCookieModal] = useState(false);
  const [cookieSettingsModal, setCookieSettingsModal] = useState(false);
  const {
    isLoading: cookiesLoading,
    error: cookiesError,
    data: cookiesData,
  } = useQuery({
    queryKey: ["cookies"],
    queryFn: getCookies,
  });
  let data = cookiesData?.data?.data;
  // console.log("Cookies ", data);
  // console.log("Cookies Inputs  ", data && JSON?.parse(data?.inputs));

  const handleCookies = () => {
    data &&
      data?.inputs &&
      JSON?.parse(data?.inputs)?.map((d) => {
        // console.log("DDD ", d);
      });
  };

  const handleSettingsModal = (value) => {
    setCookieModal(false);
    setCookieSettingsModal(value);
  };
  const openCookieModal = () => {
    setCookieModal(true);
    setCookieSettingsModal(false);
  };

  useEffect(() => {
    let cookiesState = session.get("cookies_accepted");
    // console.log("cookiesState ", cookiesState);
    {
      if (data) {
        !cookiesState && setCookieModal(true);
      }
    }
  }, [data]);

  const handleAccept = (value) => {
    session.set("cookies_accepted", value);
    setCookieModal(false);
    setCookieSettingsModal(false);
  };

  return (
    <>
      {cookieSettingsModal && (
        <CookiesSettingModal
          openCookieModal={openCookieModal}
          data={data}
          handleAccept={handleAccept}
        />
      )}

      {cookieModal && (
        <CookiesModal
          openSettingsModal={handleSettingsModal}
          handleAccept={handleAccept}
          data={data}
        />
      )}
    </>
  );
}
