import React from "react";
import ImprintDetails from "./ImprintDetails";
import { useLayoutEffect } from "react";
import { getImprintPage } from "../../services/StaticPages";
import { useQuery } from "@tanstack/react-query";
import HeroBanner from "../../components/common/HeroBanner";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Imprint() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { isLoading, error, data } = useQuery({
    queryKey: ["imprintPage"],
    queryFn: getImprintPage,
  });
  // console.log("Data ", data);
  let res = data?.data?.data.length > 0 ? data?.data?.data[0] : {};

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
      </Helmet>
      <HeroBanner
        data={{
          title: res?.title,
          description: res?.title_text,
        }}
      />
      <ImprintDetails
        data={{
          title: res?.heading,
          description: res?.content,
        }}
      />
    </div>
  );
}
