import React, { useLayoutEffect } from "react";
import { IngredientCard } from "../../components/Cards";
import { getIngredients } from "../../services/Ingredients";
import Loader from "../../components/common/Loader";
import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet";
import HeroBanner from "../../components/common/HeroBanner";
import { useLocation } from "react-router-dom";
export default function Ingredients() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    isLoading: ingredientLoading,
    error: ingredientError,
    data: ingredientData,
  } = useQuery({
    queryKey: ["ingredientListing"],
    queryFn: getIngredients,
  });
  let ingredients = ingredientData?.data?.ingredient;
  let categories = ingredientData?.data?.category;

  // const handlePageClick = (e) => {
  //   navigate(`/ingredients/?page=${e.selected + 1}`);
  // };
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
        <title> {`Indivit | Welche Zutaten sind im Smoothie?`} </title>
        <meta
          name="description"
          content="Unsere vitalen und lebendigen Smoothie-Zutaten vereinen sich zu einem harmonischen Zusammenspiel von Aromen und Nährstoffen und sind ein köstlicher und erfrischender Genuss für deine Geschmacksnerven und deine Gesundheit. Für jede unserer Zutaten haben wir spannende Informationen für dich zusammengetragen – das sollte dir bei der Auswahl für deinen Smoothie helfen."
        />
      </Helmet>
      {ingredientLoading && <Loader />}

      {/* <!-- hero banner start--> */}
      <HeroBanner
        data={{
          title: "Welche Zutaten sind im Smoothie?",
          description:
            "Unsere vitalen und lebendigen Smoothie-Zutaten vereinen sich zu einem harmonischen Zusammenspiel von Aromen und Nährstoffen und sind ein köstlicher und erfrischender Genuss für deine Geschmacksnerven und deine Gesundheit. Für jede unserer Zutaten haben wir spannende Informationen für dich zusammengetragen – das sollte dir bei der Auswahl für deinen Smoothie helfen.",
        }}
        bgImg=" !tw-bg-mixer"
      />
      {/* <!-- hero banner end--> */}

      {/* Tabs and Cards Section Start */}

      <section id="flx-nav-pils" className="!tw-pt-10 ">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <ul
                className="nav nav-pills mb-5 justify-content-center flx-pils-btn"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Alle
                  </button>
                </li>

                {categories?.map((categ, index) => {
                  return (
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-fruit-tab"
                        data-bs-toggle="pill"
                        data-bs-target={`#pills-ingredient-${categ.id}`}
                        type="button"
                        role="tab"
                        aria-controls={`pills-ingredient-${categ.id}`}
                        aria-selected="false"
                      >
                        {categ.name}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="tab-content" id="pills-tabContent">
            {/* <!-- All tabs setting --> */}
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabIndex="0"
            >
              <div className="row g-4">
                {ingredients?.map((ingred, index) => {
                  return (
                    <div
                      className="col-12 col-md-6 col-lg-4"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <IngredientCard data={ingred} />
                    </div>
                  );
                })}
              </div>
            </div>
            {/* <!-- All tabs setting end --> */}
            {/* <!-- ingredients tabs setting --> */}

            {categories?.map((categ, index) => {
              return (
                <div
                  className="tab-pane fade"
                  id={`pills-ingredient-${categ.id}`}
                  role="tabpanel"
                  //   aria-labelledby="pills-fruit-tab"
                  tabIndex={categ.id}
                >
                  <div className="row g-4">
                    {ingredients
                      ?.filter((obj) => obj.category_id == categ.id)
                      .map((ingred, index) => {
                        return (
                          <div
                            className="col-12 col-md-6 col-lg-4"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                          >
                            <IngredientCard data={ingred} />
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className="d-flex justify-content-center mt-5">
          <ReactPaginate
            breakLabel="..."
            nextLabel={<i className="fa-solid fa-greater-than"></i>}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={ingredients?.last_page}
            previousLabel={<i className="fa-solid fa-less-than"></i>}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            activeClassName="active"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
          />
        </div> */}
      </section>
      {/* Tabs and Cards Section END */}
    </div>
  );
}
