import React, { useLayoutEffect } from "react";
import session from "../../services/utils/session";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import Loader from "../../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../redux/account";
import { wishlistResetAction } from "../../redux/wishlist";
import fruitsImg from "../../assets/img/fruits.png";

import { useQuery } from "@tanstack/react-query";
import { getOrderById, getOrders } from "../../services/Orders";
import OrderCard from "./OrderCard";
import { formatToGerman2 } from "../../services/utils/germanFormat";
import moment from "moment";
import HeroBanner from "../../components/common/HeroBanner";
import { Helmet } from "react-helmet";

export default function OrderDetail() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.account.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let params = useParams();

  const handleLogout = () => {
    setLoading(true);
    session.clear();
    dispatch(logoutAction());
    dispatch(wishlistResetAction());
    setLoading(false);
    navigate("/login");
  };

  const {
    isLoading: orderLoading,
    error: orderByIdError,
    data: orderByIdData,
  } = useQuery({
    queryKey: ["orderById", params?.id],
    queryFn: () => getOrderById(params?.id),
  });
  const orders = orderByIdData?.data?.data;

  // let page = searchParams.get("page");
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
      </Helmet>
      {orderLoading && loading && <Loader />}

      <HeroBanner
        data={{
          title: user?.name,
        }}
        bgImg=" !tw-bg-faq"
      />

      <div className="flx-customcol-bg mt-5">
        <div className="container py-4 ">
          {
            /* if orders */
            orders ? (
              <>
                <div className="text-center mb-5">
                  <h4
                    className="pt-3 "
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    Deine Bestellungen
                    {/* Order Details */}
                  </h4>
                  <p>
                    Hier findest du alle Details zu deinen letzten Bestellungen
                  </p>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-4 offset-lg-2 ">
                    <div>
                      <span className="fw-bold">Name: </span>
                      <span> {orders?.name} </span>
                    </div>
                    <div>
                      <span className="fw-bold">
                        {/* apartment:  */}
                        Adresszusatz:
                      </span>
                      <span> {orders?.apartment} </span>
                    </div>
                    <div>
                      <span className="fw-bold">Adresse: </span>
                      <span> {orders?.address} </span>
                    </div>
                    <div>
                      <span className="fw-bold">Ort: </span>
                      <span> {orders?.city} </span>
                    </div>
                  </div>

                  <div className="col-lg-4 offset-lg-2">
                    <div>
                      <span className="fw-bold text-capitalize">
                        Bestellnummer:
                        {/* Order Number */}
                      </span>
                      <span> #{orders?.order_number} </span>
                    </div>
                    <div>
                      <span className="fw-bold text-capitalize">
                        {/* Shipping Date: */}
                        Lieferdatum
                      </span>
                      <span>
                        {" "}
                        {moment(orders?.shipping_date).format("DD-MM-YYYY")}
                      </span>
                    </div>
                    {/* <div>
                      <span className="fw-bold text-capitalize">
                        subscription:
                      </span>
                      <span>
                        {" "}
                        {orders?.order_box?.user_subscription[0]?.duration
                          ? orders?.order_box?.user_subscription[0]?.duration + " Days"
                          : " No Subscription"}{" "}
                      </span>
                    </div> */}
                    <div>
                      <span className="fw-bold text-capitalize">
                        {/* total price: */}
                        Gesamtsumme:
                      </span>
                      <span> {formatToGerman2(orders?.total_price)} €</span>
                    </div>
                    <div>
                      <span className="fw-bold text-capitalize">
                        Status:
                        {/* Bestellt */}
                      </span>
                      <span> {orders?.order_status}</span>
                    </div>
                  </div>
                </div>
                {orders?.order_box?.map((data, i) => {
                  return (
                    <div className="col-12 col-lg-8 offset-lg-2 mb-3">
                      <OrderCard
                        boxData={data}
                        orderData={{
                          date: orders?.shipping_date,
                          time: data?.shipping_time,
                          order_id: orders?.order_number,
                          unique_id: orders?.unique_id,
                          subscriptionName:
                            data?.user_subscription[0]?.subscription?.name,
                        }}
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              /* on condition, if no orders */
              <div className=" text-center">
                <div className="py-5">
                  <img
                    src={fruitsImg}
                    alt=""
                    width="200px"
                    className="img-fluid pb-3"
                  />
                  <h4 className=" " data-aos="fade-up" data-aos-duration="1000">
                    Order History
                  </h4>
                  <p className="pb-3">
                    You don't have any recipes in your wishlist yet.
                  </p>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
