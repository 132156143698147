import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { updatePassword } from "../../services/Account";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function UpdatePassword() {
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);
  const [isVisibile, setIsVisibile] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const otp = urlParams.get("otp");
    const email = urlParams.get("email");
    updatePassword({ otp, email, ...data })
      .then((res) => {
        if (res?.status == 200) {
          toast.success(res?.data?.message);
          navigate("/login");
        } else {
          toast.error(res?.data?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error ", err);
        toast.error(err?.data?.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
      </Helmet>
      <section id="flx-login">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-6">
              <div className="card" style={{ borderRadius: "3rem" }}>
                <div className="card-body p-5">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-md-5">
                      <h3 className="mb-2 text-center">Update Password!</h3>
                      <p className="text-center pb-4">
                        Please enter the email address associated with your
                        Indivit account. This will allow us to send you a reset
                        link.
                      </p>

                      <label className="form-label" for="typePasswordX">
                        Password
                      </label>
                      <div className="flx-login-icons pb-4 position-relative">
                        <i className="fa fa-solid fa-key flx-icon"></i>
                        <input
                          type={isVisibile ? "text" : "password"}
                          id="typePasswordX"
                          className="form-control rounded"
                          placeholder="Enter Your Password"
                          aria-label="Search"
                          aria-describedby="search-addon"
                          {...register("password", {
                            required: true,
                            // required: "Password length should be minimum 6",
                            // pattern: /^[a-zA-Z0-9-]{4,}$/,
                            minLength: 6,
                          })}
                        />
                        <i
                          className={`fa fa-solid  ${
                            isVisibile ? "fa-eye" : "fa-eye-slash"
                          }  flx-icon top-0 end-0 cursor-pointer text-black-50  `}
                          onClick={() => setIsVisibile(!isVisibile)}
                        ></i>
                        {errors?.password?.type === "required" && (
                          <p className="text-danger my-1">* Angabe notwendig</p>
                        )}
                        {errors?.password?.type === "minLength" && (
                          <p className="text-danger my-1">
                            Min Length should be 6
                          </p>
                        )}
                      </div>
                      <label className="form-label" for="typePasswordX">
                        Confirm Password
                      </label>
                      <div className="flx-login-icons pb-4 position-relative">
                        <i className="fa fa-solid fa-key flx-icon"></i>
                        <input
                          type={isVisibile ? "text" : "password"}
                          id="typePasswordX"
                          className="form-control rounded"
                          placeholder="Re-Enter Password"
                          aria-label="Search"
                          aria-describedby="search-addon"
                          {...register("password_confirmation", {
                            required: true,
                            minLength: 6,
                          })}
                        />
                        <i
                          className={`fa fa-solid  ${
                            isVisibile ? "fa-eye" : "fa-eye-slash"
                          }  flx-icon top-0 end-0 cursor-pointer text-black-50  `}
                          onClick={() => setIsVisibile(!isVisibile)}
                        ></i>
                        {errors?.password_confirmation?.type === "required" && (
                          <p className="text-danger my-1">* Angabe notwendig</p>
                        )}
                        {errors?.password_confirmation?.type ===
                          "minLength" && (
                          <p className="text-danger my-1">
                            Min Length should be 6
                          </p>
                        )}
                      </div>
                      <button
                        disabled={loading}
                        className="btn-solid-success w-100 d-flex justify-content-center align-items-center"
                        type="submit"
                      >
                        {loading && (
                          <div
                            class="spinner-border text-light me-3"
                            role="status"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        )}
                        Update Password
                      </button>
                    </div>
                  </form>
                  <p className="text-center">
                    <a href="login" className="text-theme-success">
                      {" "}
                      &nbsp; &nbsp;Back to Login
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
