import React, { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import HeroBanner from "../components/common/HeroBanner";
import { useLocation } from "react-router-dom";

const faq = [
  {
    title: "Was sind Smoothie?",
    description:
      "Smoothies sind Getränke, die durch das Mixen von Obst, Gemüse und anderen Zutaten hergestellt werden. Sie sind eine gesunde und erfrischende Art, wichtige Nährstoffe zu sich zu nehmen.",
  },
  {
    title: "Was sind die Vorteile von Smoothies?",
    description:
      "Das Trinken von Smoothies kann Dir helfen, Deinen täglichen Nährstoffbedarf zu decken, Dein Energieniveau zu steigern, die Verdauung zu verbessern und Deine allgemeine Gesundheit und Dein Wohlbefinden zu fördern.",
  },

  {
    title: "Kann ich zu Hause Smoothies machen?",
    description:
      "Ja, Smoothies lassen sich zu Hause ganz einfach mit einem Hochleistungs-Mixer zubereiten. Wähle einfach Dein Lieblingsobst und -gemüse aus, füge z.B. die gewünschten Proteinquellen oder gesunde Fette hinzu und mixe Deinen Smoothie, bis er schön seidig glatt ist.",
  },
  {
    title: "Welche Zutaten werden häufig in Smoothies verwendet?",
    description:
      "Übliche Zutaten für Smoothies sind Bananen, Beeren, Blattgemüse, Zitrusfrüchte und meist eine Flüssigkeit der Wahl. Andere Zutaten wie Kokosraspeln, Leinsamen und Nussbutter können ebenfalls hinzugefügt werden, um einen zusätzlichen Nährwert zu erhalten.",
  },
  {
    title: "Können Smoothies als Mahlzeitenersatz verwendet werden?",
    description:
      "Smoothies können als Mahlzeitenersatz verwendet werden, wenn sie mit einem ausgewogenen Verhältnis von Eiweiß, gesunden Fetten und Kohlenhydraten zubereitet werden, um die notwendigen Nährstoffe und Energie zu liefern. Es ist jedoch wichtig, darauf zu achten, dass sie als Teil einer ausgewogenen Ernährung konsumiert werden.",
  },
  {
    title: "Sind Smoohties gesund?",
    description:
      "Smoothies können gesund sein, je nachdem, welche Zutaten verwendet werden. Wenn sie mit frischem Obst und Gemüse zubereitet werden, können sie eine gute Quelle für wichtige Vitamine und Mineralstoffe sein. Smoothies, die Zuckerzusätze und wenig Ballaststoffe enthalten, sind dagegen nicht so gesund.",
  },
  {
    title: "Können Smoothies zur Gewichtsabnahme verwendet werden?",
    description:
      "Smoothies können Teil eines gesunden Abnehmprogramms sein, da sie eine kalorienarme und nährstoffreiche Mahlzeit oder Zwischenmahlzeit darstellen. Es ist jedoch wichtig, Zutaten zu wählen, die wenig Kalorien und viele Ballaststoffe enthalten, um das Ziel der Gewichtsabnahme zu unterstützen.",
  },
  {
    title: "Wie lange ist ein Smoothie haltbar?",
    description:
      "Unbehandelte Smoothies sollten sofort nach der Zubereitung verzehrt werden, um einen maximalen Nährwert zu gewährleisten. Alle indivit Smoothies sind mit einem schonenden Hochdruckverfahren behandelt. Damit ist sichergestellt, dass der Nährwert auf einem hohen Niveau bleibt und jeder Smoothies trotzdem mindestens 4 Wochen haltbar ist.",
  },
  {
    title:
      "Sind Smoothies für Menschen mit Ernährungseinschränkungen geeignet?",
    description:
      "Smoothies können für eine Vielzahl von Ernährungseinschränkungen hergestellt werden, z. B. vegan, glutenfrei und fructosearm. Es ist wichtig, dass Du Zutaten wählst, die für Deine individuellen Ernährungsbedürfnisse geeignet sind.",
  },
  {
    title: "Sind Smoothies für Kinder geeignet?",
    description:
      "Smoothies sind eine gute Möglichkeit, Kinder mit einer Vielzahl von Obst und Gemüse vertraut zu machen. Es ist jedoch wichtig, Zutaten zu wählen, die für ihr Alter und ihre Ernährungsbedürfnisse geeignet sind.",
  },
];
export default function FAQ() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
        <title> {`Indivit | FAQ`} </title>
        <meta
          name="description"
          content={`Unsere FAQs machen es Dir leicht, sich in der Welt der Smoothies zurechtzufinden - finde schnelle und hilfreiche Antworten auf alle Deine Fragen an einem Ort`}
        />
      </Helmet>
      <HeroBanner
        data={{
          title: "FAQs",
          description:
            " Unsere FAQs machen es Dir leicht, sich in der Welt der Smoothies zurechtzufinden - finde schnelle und hilfreiche Antworten auf alle Deine Fragen an einem Ort",
        }}
        bgImg=" !tw-bg-faq"
      />

      {/* <!-- accordion section --> */}
      <section id="flx-faqs-accordion" className="!tw-pt-10 ">
        <div className="container">
          <div className="accordion" id="accordiion_cont">
            <div className="row">
              {/* <!-- colum one --> */}
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 flx-faqs-col">
                <div className="accordion">
                  {faq?.slice(0, 5).map(({ title, description }, i) => {
                    return (
                      <div className="accordion-item shadow-sm">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className={`accordion-button flx-faqs-border ${
                              i !== 0 ? "collapsed" : ""
                            } `}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse_${i}`}
                            aria-expanded={i == 0 ? true : false}
                            aria-controls="collapseOne"
                          >
                            {title}
                          </button>
                        </h2>
                        <div
                          id={`collapse_${i}`}
                          className={`accordion-collapse collapse ${
                            i == 0 ? "show" : ""
                          }`}
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordiion_cont"
                        >
                          <div className="accordion-body">
                            <p>{description}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 flx-faqs-col">
                <div className="accordion">
                  {faq?.slice(5).map(({ title, description }, i) => {
                    return (
                      <div className="accordion-item shadow-sm">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className={`accordion-button flx-faqs-border collapsed`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse_${i}_${i}`}
                            aria-expanded={i == 0 ? true : false}
                            aria-controls="collapseOne"
                          >
                            {title}
                          </button>
                        </h2>
                        <div
                          id={`collapse_${i}_${i}`}
                          className={`accordion-collapse collapse`}
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordiion_cont"
                        >
                          <div className="accordion-body">
                            <p>{description}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- accordion section --> */}
    </div>
  );
}
