import React, { useLayoutEffect } from "react";
import congratulationsImg from "../assets/img/congratulations.png";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function Congratulations() {
  const {pathname} = useLocation()
  // let page = searchParams.get("page");
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
       <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
      </Helmet>
      <div className="pt-140 tw-max-w-7xl tw-mx-auto">
        <div className="container text-center">
          <img
            width={400}
            height="auto"
            src={congratulationsImg}
            className="img-fluid mt-5"
          />
        </div>
        <p className="tw-max-w-4xl tw-mx-auto tw-text-center tw-text-lg tw-font-medium">
          Du hast dich erfolgreich registriert. Bitte prüfe deine E-Mails, um
          dein Kundenkonto zu aktivieren. Du kannst diese Seite jetzt schließen.
          Deine Kreation findest du im vorherigen Tab.
        </p>
      </div>
    </div>
  );
}
