import React, { useEffect, useLayoutEffect, useState } from "react";
import productImg from "../../assets/img/products-popup.png";
import { getSmoothieById } from "../../services/Smoothie";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/common/Loader";
import { baseURL } from "../../services/Adapter/customAxios";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import { TextSkelton } from "../../components/common/Common";
import StarRating from "../../components/common/StarRating";
import { useForm, Controller } from "react-hook-form";
import {
  addSmoothieFeedback,
  getProductByProdID,
  postFeedback,
} from "../../services/UserFeedback";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

export default function SmoothieFeedback() {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const orderId = searchParams.get("order_id");
  const commonImg = useSelector((state) => state.settings?.boxImg);
  const isAuthenticated = useSelector((state) => state.account.isAuthenticated);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);

  const {
    isLoading: smoothieByIdLoading,
    error: smoothieByIdError,
    data: dataByProdID,
    // Production ID
  } = useQuery({
    queryKey: ["getByProdId", params?.id],
    queryFn: () => getProductByProdID(params?.id),
  });

  // console.log("Prod By Production ID ", dataByProdID);

  useEffect(() => {
    if (
      dataByProdID &&
      (dataByProdID?.data?.message == "No Order Found" ||
        !dataByProdID?.data?.data)
    ) {
      toast.error("Für diesen Benutzer wurde keine Bestellung gefunden");
    }
  }, [dataByProdID]);

  // debugger;
  let data;
  let product_type = dataByProdID?.data?.product_type;
  // Product Type 0 == Box
  // Product Type 1 == Smoothie
  if (isAuthenticated) {
    if (product_type == 0) {
      data = dataByProdID?.data?.data?.order_box?.find(
        (d) => d.production_id == params?.id
      );
    } else if (product_type == 1) {
      data = dataByProdID?.data?.data?.order_box_smoothies?.find(
        (d) => d.production_id == params?.id
      );
    }
  } else {
    if (product_type == 0) {
      data = dataByProdID?.data?.data?.smoothie_box?.box;
    } else if (product_type == 1) {
      data = dataByProdID?.data?.data?.order?.order_box_smoothies?.find(
        (d) => d.production_id == params?.id
      );
    }
  }
  // console.log("Data Find ", data);
  // debugger;
  const mutation = useMutation({
    mutationFn: postFeedback,
    onSuccess: (res) => {
      // Invalidate and refetch
      // console.log("Response From Feedback ", res);
      if (res?.response?.status == 401) {
        toast.error("bitte einloggen zum Fortfahren");
        // Login to Proceed
      } else if (res?.status == 200) {
        toast.success(res.data.message);
        navigate("/thank_you");
      } else {
        toast.error("Etwas ist schief gelaufen");
      }
      setLoading(false);
    },
    onError: (err) => {
      setLoading(false);
      toast.error(
        err.response.status == 401
          ? "bitte einloggen zum Fortfahren"
          : err.response.data.message
      );
    },
  });
  const onSubmit = (data) => {
    setLoading(true);
    mutation.mutate({
      // order_id: orderId,
      // unique_id: params?.id,
      production_id: params?.id,
      ...data,
    });
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
      </Helmet>
      <div>
        {(loading || smoothieByIdLoading) && <Loader />}
        <section id="flx-hero-rdetailed">
          <div className="container">
            <div className="">
              <nav aria-label="breadcrumb" className="px-0">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  {product_type == 0 && (
                    <li className="breadcrumb-item">
                      <Link to="/boxes">Boxen</Link>
                    </li>
                  )}
                  {product_type == 1 && (
                    <li className="breadcrumb-item">
                      <Link to="/rightrecipes">Smoothie Rezepte</Link>
                    </li>
                  )}
                  <li className="breadcrumb-item active" aria-current="page">
                    {isAuthenticated &&
                      (smoothieByIdLoading ? (
                        <TextSkelton />
                      ) : product_type == 0 ? (
                        data?.smoothie_box?.box?.name
                      ) : (
                        data?.smoothie?.name
                      ))}
                    {!isAuthenticated &&
                      (smoothieByIdLoading ? (
                        <TextSkelton />
                      ) : product_type == 0 ? (
                        dataByProdID?.data?.data?.smoothie_box?.box?.name
                      ) : (
                        data?.smoothie?.name
                      ))}
                  </li>
                </ol>
              </nav>
            </div>

            <div className="row d-flex pt-0 pt-md-5 align-items-center">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="text-center flx-recipes-bg">
                  {product_type == 0 && (
                    <img
                      src={
                        data?.smoothie_box?.box.smoothie_image.length > 0
                          ? baseURL +
                            "smoothie_box/" +
                            data?.smoothie_box?.box.smoothie_image[0].images
                          : data?.smoothie_image.length > 0
                          ? baseURL +
                            "smoothie_box/" +
                            data?.smoothie_image[0]?.images
                          : commonImg
                      }
                      className="img-fluid flx-rdetailed-animation max-h-460 w-100"
                      alt=""
                    />
                  )}
                  {product_type == 1 && (
                    <img
                      src={
                        data?.smoothie?.smoothie_picture
                          ? baseURL +
                            "smoothie/" +
                            data?.smoothie?.smoothie_picture?.picture
                          : commonImg
                      }
                      className="img-fluid flx-rdetailed-animation max-h-460 w-100"
                      alt=""
                    />
                  )}
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 flx-rdetailed-leave">
                <div className="text-left">
                  <div className="d-flex align-items-center ">
                    <span className="fw-bold fs-2">
                      {smoothieByIdLoading ? (
                        <TextSkelton />
                      ) : product_type == 0 ? (
                        data?.smoothie_box?.box?.name || data?.name
                      ) : (
                        data?.smoothie?.name
                      )}
                    </span>
                    {(data?.smoothie_box?.created_by == 1 ||
                      data?.smoothie?.created_by == 1) && (
                      <span
                        className={`badge rounded-pill   text-uppercase bg-info ms-2`}
                      >
                        Customized
                      </span>
                    )}
                  </div>

                  <p>
                    {data?.smoothie_box?.short_detail ||
                      dataByProdID?.data?.data?.smoothie_box?.short_detail ||
                      data?.smoothie?.headline}
                  </p>
                  <p> {data?.recipe_text} </p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <label>Haben wir deinen Geschmack getroffen?</label>
                  <Controller
                    name="starRating"
                    control={control}
                    defaultValue={0}
                    rules={{
                      validate: (value) => value > 0 || "Please select.",
                    }}
                    render={({ field }) => (
                      <StarRating
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  {errors.starRating && (
                    <p className="text-danger">{errors.starRating.message}</p>
                  )}
                  {/* <div>
                    <label>Wie fandest deinen Smoothie?</label>
                    <StarRating rating={rating} onChange={setRating} />
                  </div> */}
                  <div>
                    <label>Hast du sonst noch Feedback? (optional)</label>
                    <div className="position-relative my-3">
                      <label
                        className="position-absolute bg-light fs-10 px-1"
                        style={{ top: "-10px", left: "10px" }}
                      >
                        Zusätzliches Feedback
                      </label>
                      <textarea
                        name=""
                        className="form-control bg-transparent rounded-3"
                        placeholder="Bitte Feedback hier eingeben..."
                        id=""
                        cols="54"
                        rows="4"
                        {...register("comment")}
                      />
                    </div>
                  </div>
                  <div className="text-end py-3">
                    <button className="btn btn-success bg-theme-success">
                      Absenden
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
