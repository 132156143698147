import React, { useRef, useState, useEffect } from "react";

import leftIcon from "../assets/object/left.png";
import rightIcon from "../assets/object/right.png";
import { baseURL } from "../services/Adapter/customAxios";
import { useQuery } from "@tanstack/react-query";
import { getHomeSlider } from "../services/Slider";

import image_1 from "../assets/img/hero-image.png";
import image_2 from "../assets/img/Combo.png";
import thumb1 from "../assets/img/Thumbnail_3.png";
import { HeroBgSVG } from "../assets/icon/svgIcons";
import { Link } from "react-router-dom";

export default function HomeCarousel() {
  const {
    isLoading: sliderLoading,
    error: sliderError,
    data: sliderData,
  } = useQuery({
    queryKey: ["homeSlider"],
    queryFn: getHomeSlider,
  });
  let data = sliderData?.data?.data || [];
  const carouselRef = useRef(null);

  const [bgImages, setBgImages] = useState([]);
  const [thumbnailImages, setThumbnailImages] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [productBottomImages, setProductBottomImages] = useState([]);
  const bgColors = ["var(--green)", "#ff6704", "#ecb177", "#ff6704"];
  useEffect(() => {
    // Fetch and cache the images
    const fetchImages = async () => {
      try {
        const images1 = await Promise.all(
          data.map((url) => {
            return new Promise((resolve, reject) => {
              const image = new Image();
              image.src = baseURL + "slider/" + url?.background_image;
              image.onload = () => resolve(image);
              image.onerror = (error) => reject(error);
            });
          })
        );
        setBgImages(images1);
        const images2 = await Promise.all(
          data.map((url) => {
            return new Promise((resolve, reject) => {
              const image = new Image();
              image.src = baseURL + "slider/" + url?.slide_thumbnail;
              image.onload = () => resolve(image);
              image.onerror = (error) => reject(error);
            });
          })
        );
        setThumbnailImages(images2);
        const images3 = await Promise.all(
          data.map((url) => {
            return new Promise((resolve, reject) => {
              const image = new Image();
              image.src = baseURL + "slider/" + url?.image_1;
              image.onload = () => resolve(image);
              image.onerror = (error) => reject(error);
            });
          })
        );
        setProductImages(images3);
        const images4 = await Promise.all(
          data.map((url) => {
            return new Promise((resolve, reject) => {
              const image = new Image();
              image.src = baseURL + "slider/" + url?.image_2;
              image.onload = () => resolve(image);
              image.onerror = (error) => reject(error);
            });
          })
        );
        setProductBottomImages(images4);
      } catch (error) {
        console.error("Error caching images:", error);
      }
    };

    fetchImages();
  }, [sliderData]);

  const updateBg = () => {
    let carosel = carouselRef.current;
    // setTimeout(() => {
    // }, 350);
    if (carosel) {
      data?.map((d, index) => {
        if (
          carosel.querySelector(`.carousel-item.slide-${index}.active`) !== null
        ) {
          setActiveIndex(index);
          //   carosel.style.backgroundImage = `url(
          //   ${bgImages[index]?.src}
          // )`;
        }
      });
    }
  };

  useEffect(() => {
    const carousel = document.getElementById("carouselExampleDark");
    carousel.addEventListener("slid.bs.carousel", updateBg);
  });

  return (
    <div className="home-hero-bg position-relative h-md-900">
      {data?.map((d, i) => {
        return (
          <div
            key={i}
            className="position-absolute"
            style={{ visibility: "hidden" }}
          >
            <img width="100px" className=" img-fluid" src={bgImages[i]?.src} />
            <img
              width="100px"
              className=" img-fluid"
              src={productImages[i]?.src}
            />
            <img
              width="100px"
              className=" img-fluid"
              src={productBottomImages[i]?.src}
            />
          </div>
        );
      })}
      <HeroBgSVG filled={bgColors[activeIndex]} />
      <section
        id="flx-here-section"
        ref={carouselRef}
        className="fade-in position-absolute start-0 end-0 top-110px "
        // style={{
        //   backgroundImage: `url(${
        //     sliderLoading ? bgImages[0]?.src : bg_image
        //   })`,
        // }}
      >
        {/* <!-- hero banner start--> */}
        <div className="container pt-5 pt-md-0 pb-md-5 position-relative">
          <div
            id="carouselExampleDark"
            className="carousel carousel-dark slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              {sliderLoading || data.length == 0 ? (
                <div
                  className={`carousel-item slide-0 active `}
                  data-bs-interval="8000"
                >
                  <div className="row d-flex flx-slider-top carousel_text_bg">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flx-text-col">
                      <div className="text-left hsn-preline">
                        <span className="flx-stock">Smoothies</span>
                        <h1>
                          <span className="hsn-smoothies">
                            Smoothies zum selber Mixen
                          </span>
                        </h1>
                        <h2> Smoothies, so individuell wie du selbst </h2>
                        <p className="pt-3 pb-3">
                          Über 78 Milliarden Variationen machen es möglich: Der
                          beste Smoothie der Welt ist DEIN Smoothie. Starte
                          direkt im Online Smoothie Mixer mit der Konfiguration
                          deines eigenen Smoothies.
                        </p>
                        <button
                          type="button"
                          className="btn btn-primary btn-solid-success"
                        >
                          Online Smoothie Mixer
                        </button>
                      </div>
                      <div></div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-none d-md-block">
                      <div className="flx-animate-relative">
                        <img
                          src={image_1}
                          className="img-fluid flx-oneabsolute top-animate "
                          alt=""
                        />
                        <img
                          src={image_2}
                          className="img-fluid flx-twoabsolute bottom-animate"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                data?.map((slide, index) => {
                  return (
                    <div
                      key={index}
                      className={`carousel-item slide-${index} ${
                        index == 0 && "active"
                      } `}
                      data-bs-interval="8000"
                    >
                      <div className="row d-flex flx-slider-top carousel_text_bg">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flx-text-col hero-text-animate ">
                          <div className="text-left hsn-preline">
                            <span className="flx-stock">Smoothies</span>
                            <h1>
                              <span className="hsn-smoothies">
                                {slide?.title}
                              </span>
                            </h1>
                            <h3> {slide?.heading} </h3>
                            <p className="pt-3 pb-3">{slide?.description}</p>
                            <Link
                              to="/smoothiemixer"
                              // target="_blank"
                              type="button"
                              className="btn btn-primary btn-solid-success"
                            >
                              Online Smoothie Mixer
                            </Link>
                          </div>
                          <div></div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-none d-md-block">
                          <div className="flx-animate-relative">
                            <img
                              src={productImages[index]?.src || image_1}
                              className="img-fluid flx-oneabsolute top-animate "
                              alt=""
                            />
                            <img
                              src={productBottomImages[index]?.src || image_2}
                              className="img-fluid flx-twoabsolute bottom-animate"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>

            {/* Carousel Indicators / Dots */}
            <div className="carousel-indicators flx-dots-slider mb-0">
              {sliderLoading || data.length == 0 ? (
                <img
                  src={thumb1}
                  alt=""
                  width="120px"
                  className="img-fluid active flx-dots-style"
                />
              ) : (
                data?.map((thumb, index) => {
                  return (
                    <img
                      key={index}
                      src={thumbnailImages[index]?.src || thumb1}
                      alt=""
                      width="120px"
                      height="100px"
                      className="img-fluid active flx-dots-style"
                      data-bs-target="#carouselExampleDark"
                      data-bs-slide-to={index}
                      aria-current="true"
                      aria-label="Slide 1"
                    />
                  );
                })
              )}
            </div>
            {/* <!-- dots style --> */}
            {/* <!-- dots style --> */}
          </div>
          {/* <!-- button style --> */}
          <div className="flx-slider-controller">
            <button
              className="carousel-control-prev flx-prevslider-btn"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="prev"
            >
              {/* <!-- <span className="carousel-control-prev-icon" aria-hidden="true"></span> --> */}
              <img src={leftIcon} alt="" className="img-fluid" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next flx-nextslider-btn"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="next"
            >
              {/* <!-- <span className="carousel-control-next-icon" aria-hidden="true"></span> --> */}
              <img src={rightIcon} alt="" className="img-fluid" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
