import { POST, GET } from "./Adapter/base";

export const placeOrder = (data) => {
  return POST(`/kunden/bestellung`, data);
};
export const getOrders = () => {
  return GET(`bestellungs`);
};
export const getOrderById = (id) => {
  return GET(`bestellung/${id}`);
};
export const getSubscriptionPlan = () => {
  return GET(`subscription/period`);
};
export const getSubscriptions = () => {
  return GET(`getSubscriptionByUser`);
};
export const cancelSubscription = (data) => {
  return POST(`cancelSubscriptionByUser`, data);
};
export const getDiscount = (data) => {
  return POST(`discount`, data);
};
export const shippingDate = () => {
  return POST(`check_shipping_date`);
};
export const setShippingDate = (data) => {
  return POST(`check_shipping_date`, data);
};

export const getShippingCost = () => {
  return GET(`delivery_cost/list`);
};
export const stripeCheckout = () => {
  return POST(`checkout-session`);
};

export const getShippingDate = () => {
  return GET(`shipping_date`);
};

export const createKlarnaOrder = (data) => {
  return POST(`klarna/order`, data);
};

export const getKlarnaToken = (data) => {
  return POST(`klarna/token`, data);
};
