import React, { useLayoutEffect } from "react";
import WhyIndivitHero from "./WhyIndivitHero";
import AboutRecipe from "../../components/AboutRecipe";
import WhyDetails from "./WhyDetails";
import RecipeBanner from "./RecipeBanner";
import VisionRecipeSection from "../../components/section/VisionRecipeSection";
import { useQuery } from "@tanstack/react-query";
import { getWhyIndivitPage } from "../../services/StaticPages";
import WhyIndivitData from "./JSONData.json";
import compareImg from "../../assets/object/Chart.png";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

export default function WhyIndivit() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { isLoading, error, data } = useQuery({
    queryKey: ["whyIndivitPage"],
    queryFn: getWhyIndivitPage,
  });
  // console.log("res ", data);
  let res = data?.data?.data.length > 0 ? data?.data?.data[0] : {};

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
        <title> {`Indivit | Warum Indivit`} </title>
        {res?.main_description && (
          <meta name="description" content={res?.main_description} />
        )}
      </Helmet>
      <WhyIndivitHero
        data={{
          title: res?.main_title,
          description: res?.main_description,
        }}
      />

      <div className="flx-hero-about !tw-h-auto max-md:!tw-px-4">
        <p className="text-center pb-2">{res?.main_description}</p>
      </div>

      <WhyDetails
        data={{
          title: res?.section_heading,
          description: res?.section_description,
        }}
      />
      <AboutRecipe data={WhyIndivitData?.indivit} color="#f5eac9" />

      <div className="text-center py-5">
        <img width={600} className="img-fluid" src={compareImg} />
      </div>
      <RecipeBanner />
      <VisionRecipeSection />
    </div>
  );
}
