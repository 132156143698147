import React from "react";
import productImg from "../../assets/icon/shoppingcartadd.jpg";
import delIcon from "../../assets/icon/Recycle-Bin.png";
import editIcon from "../../assets/icon/edit.png";
import { useCart } from "react-use-cart";
import { useQuery } from "@tanstack/react-query";
import { getBoxSize } from "../../services/SmoothieBox";
import { useState } from "react";
import ConfirmWishModal from "../../components/Modal/ConfirmWishModal";
import ModalContainer from "../../components/Modal/ModalContainer";
import { useSelector } from "react-redux";
import { formatToGerman2 } from "../../services/utils/germanFormat";
import { baseURL } from "../../services/Adapter/customAxios";

export default function CartCard({ box, subscriptionPlan }) {
  const [modalVisible, setModalVisible] = useState(false);
  const commonImg = useSelector((state) => state.settings?.smoothieImg);

  const { removeItem } = useCart();

  const {
    isLoading: boxSizeLoading,
    error: boxSizeError,
    data: boxSizeData,
  } = useQuery({
    queryKey: ["getBoxSize"],
    queryFn: getBoxSize,
  });
  const handleRemove = () => {
    setModalVisible(false);
    removeItem(box?.id);
  };
  let boxSize = boxSizeData?.data?.data?.find((d, i) => d.id == box?.box_size);
  // console.log(
  //   subscriptionPlan,
  //   subscriptionPlan?.find((d) => d.id == box?.subscription_id)
  // );
  return (
    <div>
      <ModalContainer
        isOpen={modalVisible}
        closeModal={() => setModalVisible(false)}
      >
        <ConfirmWishModal
          setModalVisible={setModalVisible}
          fun={handleRemove}
          innerHtml={
            "Möchtest du wirklich diese Box aus dem Warenkorb löschen?"
          }
          okLabel="Ja, Box aus dem Warenkorb entfernen"
          // isLoading={isLoading}
        />
      </ModalContainer>
      <div className="flx-box-shopping p-4 rounded-3 bg-light">
        <div className="row">
          <div className="col-8 col-md-6">
            <span className="fs-5 fw-bold">{box?.name}</span> x
            <span className="fw-bold">{box?.quantity}</span>
            <p>
              <span
                className={`${
                  box?.discounted && "text-decoration-line-through"
                }`}
              >
                {formatToGerman2(
                  box?.discounted
                    ? box?.actualPrice * box?.quantity
                    : box?.itemTotal
                )}
                &nbsp;€
              </span>
              {box?.discounted && (
                <>
                  <span className="ms-2">
                    {formatToGerman2(
                      (parseFloat(box?.actualPrice) -
                        (box?.discounted / 100) * box?.actualPrice) *
                        box?.quantity
                    )}
                    &nbsp;€
                  </span>
                  <span className="ms-2">
                    ({box?.discounted}% Rabatt auf dein Abo )
                  </span>
                </>
              )}
            </p>
          </div>
          <div className="col-4 col-md-6 text-end">
            {/* <img
              height="30px"
              width="30px"
              src={editIcon}
              alt=""
              className="img-fluid"
            />
            &nbsp; */}
            <img
              type="button"
              onClick={() => setModalVisible(true)}
              height="30px"
              width="30px"
              src={delIcon}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 my-1">
            <div className="flx-csmoothies-style py-3 text-truncate">
              {boxSize?.size} Smoothies ( {boxSize?.variant} )
            </div>
          </div>
          <div className="col-12 col-sm-6 my-1">
            <div className=" flx-csmoothies-style py-3 text-capitalize text-truncate">
              {box?.subscriptionPlan
                ? "Abo | " +
                  subscriptionPlan?.find((d) => d.id == box?.subscription_id)
                    ?.name
                : "Einmaliger Kauf | Kein Abo"}
              {/* one time | no subscription */}
            </div>
          </div>
        </div>
        <div className="row pt-4">
          {box?.boxSmoothies?.map((item, index) => {
            // console.log("Order Item ", item);
            return (
              <div key={index} className="col-6 col-md-4 mb-3">
                <div className="row align-items-center">
                  <div className=" col-4">
                    <img
                      src={
                        item?.smoothie?.smoothie_picture?.picture
                          ? baseURL +
                            "smoothie/" +
                            item?.smoothie?.smoothie_picture?.picture
                          : commonImg
                      }
                      alt=""
                      className="img-fluid rounded-3 shadow-sm"
                    />
                  </div>
                  <div className=" col-8">
                    <h5 className="m-0 text-truncate">
                      {item?.smoothie?.name}
                    </h5>
                    <p className="m-0">{item?.qty} x</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
