import React from "react";
import { SkeltonCard, TestimonialCard } from "../Cards";
import Carousel from "react-multi-carousel";
import leftIcon from "../../assets/object/left.png";
import rightIcon from "../../assets/object/right.png";
import { getTestimonials } from "../../services/UserFeedback";
import { useQuery } from "@tanstack/react-query";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 700 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 700, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    arrows: false,
  },
};
const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className=" tw-top-1/2 position-absolute  w-100  tw-hidden sm:tw-block">
      <button
        className=" btn position-absolute start-0 px-0 shadow-none"
        onClick={() => previous()}
      >
        <img
          height="114px"
          width="62px"
          className="img-fluid "
          src={leftIcon}
        />
      </button>
      <button
        className="btn position-absolute end-0 px-0 shadow-none"
        onClick={() => next()}
      >
        <img
          height="114px"
          width="62px"
          className="img-fluid "
          src={rightIcon}
        />
      </button>
    </div>
  );
};
export default function Testimonials() {
  const { isLoading, error, data } = useQuery({
    queryKey: ["testmonialsListing"],
    queryFn: getTestimonials,
  });
  // console.log("Dtata ", data);
  return (
    <div className="tw-pt-20 " id="testimonials">
      <div className="tw-text-center">
        <h2
          data-aos="fade-up"
          data-aos-duration="1000"
          className="tw-font-bold lg:tw-text-6xl md:tw-text-5xl sm:tw-text-4xl tw-text-3xl"
        >
          Empfehlungen vom Rookie bis zum Smoothie Sommelier
        </h2>
        <p
          data-aos="fade-up"
          data-aos-duration="1000"
          className="tw-text-theme"
        >
          Was unsere Kunden sagen
        </p>
      </div>
      <div className="">
        <div className=" m-auto position-relative ">
          <Carousel
            responsive={responsive}
            itemclassName="px-5"
            autoPlay={true}
            arrows={false}
            renderButtonGroupOutside={true}
            customButtonGroup={<ButtonGroup />}
            infinite={true}
            showDots
            containerClass="lg:tw-pt-40 md:tw-pt-32  tw-pt-20 lg:tw-pb-20 md:tw-pb-16 tw-pb-10"
          >
            {isLoading || data?.data?.data?.length == 0
              ? Array.from(Array(8))?.map((box, index) => {
                  return (
                    <div
                      key={index}
                      className="px-md-5"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <SkeltonCard profileStyle={true} />
                    </div>
                  );
                })
              : data?.data?.data?.map((testi, index) => {
                  return (
                    <div
                      key={index}
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <TestimonialCard data={testi} />
                    </div>
                  );
                })}
          </Carousel>

          {/* {[1, 2, 3, 4, 5, 6].map((prod, index) => {
              return <ProductCard />;
            })} */}
        </div>

        {/* <TestimonialCard /> */}
      </div>
    </div>
  );
}
