import React from "react";
import fruitsImg from "../../assets/img/fruits.png";
import { Link } from "react-router-dom";

export default function EmptyWishlist({ title, label1, label2, link1, link2 }) {
  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
      <div className="py-5">
        <img src={fruitsImg} alt="" width="200px" className="img-fluid pb-3" />
        <p className="pb-3">{title}</p>
        {link1 && (
          <Link to={link1} className="btn btn-primary btn-solid-success">
            {label1}
          </Link>
        )}
        &nbsp;&nbsp;&nbsp;
        {link2 && (
          <Link to={link2} className="btn btn-primary btn-outline-success">
            {label2}
          </Link>
        )}
      </div>
    </div>
  );
}
