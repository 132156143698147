import React from "react";
import { Link, useNavigate } from "react-router-dom";
import FooterTop from "./FooterTop";
import logo from "../../assets/icon/logo1.png";
import { useQuery } from "@tanstack/react-query";
import { getLimitedBoxes } from "../../services/SmoothieBox";
import { useSelector } from "react-redux";
export default function Footer() {
  const {
    isLoading: boxLoading,
    error: boxError,
    data: boxData,
  } = useQuery({
    queryKey: ["limitedboxListing"],
    queryFn: getLimitedBoxes,
  });
  const boxes = boxData?.data?.data;
  const settings = useSelector((state) => state?.settings?.settings);

  // console.log("Site Setings ", settings);
  return (
    <div>
      {/* <!-- footer start --> */}
      <FooterTop />
      <footer id="flx-footer">
        <div className="container">
          <div className="row d-flex pb-5 pb-md-0">
            <div className=" d-none d-lg-block pb-5 ps-sm-1">
              <img
                className=" img-fluid"
                src={logo}
                width="80px"
                alt="Indivit"
              />
            </div>
            {/* <!-- shop colum --> */}
            <div className="col-12 custom-md-col pb-3">
              <h6 className="text-uppercase fw-bold">Shop</h6>
              <ul className="list-group list-unstyled">
                {boxes?.slice(0, 4).map((box, index) => {
                  return (
                    <li key={index} className="lh-lg" aria-current="true">
                      <Link
                        to={`/b/${box?.unique_id}`}
                        className="text-decoration-none text-reset"
                      >
                        {box?.name}
                      </Link>
                    </li>
                  );
                })}

                <li className="lh-lg" aria-current="true">
                  <Link
                    to={`/boxes`}
                    className="text-decoration-none text-reset"
                  >
                    Alle Boxen ansehen
                  </Link>
                </li>
              </ul>
            </div>

            {/* <!-- shop colum end--> */}
            {/* <!-- SMOOTHIE colum--> */}
            <div className="col-6 custom-md-col pb-3">
              <h6 className="text-uppercase fw-bold">Smoothies</h6>
              <ul className="list-group list-unstyled">
                <li className="lh-lg" aria-current="true">
                  <Link
                    className="text-decoration-none text-reset"
                    to="/rightrecipes"
                  >
                    Smoothie Rezepte
                  </Link>
                </li>
                <li className="lh-lg" aria-current="true">
                  <Link
                    className="text-decoration-none text-reset"
                    to="/smoothiemixer"
                  >
                    Online Smoothie Mixer
                  </Link>
                </li>
                <li className="lh-lg" aria-current="true">
                  <Link
                    className="text-decoration-none text-reset"
                    to="/ingredients"
                  >
                    Zutaten
                  </Link>
                </li>
                <li className="lh-lg" aria-current="true">
                  <Link
                    className="text-decoration-none text-reset"
                    to="/custombox"
                  >
                    Eigene Box erstellen
                  </Link>
                </li>
              </ul>
            </div>
            {/* <!-- SMOOTHIE colum end--> */}
            {/* <!-- discover colum--> */}
            <div className="col-6 custom-md-col pb-3">
              <h6 className="text-uppercase fw-bold">Entdecken</h6>
              <ul className="list-group list-unstyled">
                {/* <li className="lh-lg" aria-current="true">
                  <Link className="text-decoration-none text-reset" to="/story">
                    Story
                  </Link>
                </li>
                <li className="lh-lg" aria-current="true">
                  <Link
                    className="text-decoration-none text-reset"
                    to="/sustainability"
                  >
                    Sustainability
                  </Link>
                </li>
                <li className="lh-lg" aria-current="true">
                  <Link className="text-decoration-none text-reset" to="/hpp">
                    HPP Procedure
                  </Link>
                </li>
                <li className="lh-lg" aria-current="true">
                  <Link
                    className="text-decoration-none text-reset"
                    to="/imprint"
                  >
                    Imprint
                  </Link>
                </li> */}
                <li className="lh-lg" aria-current="true">
                  <Link className="text-decoration-none text-reset" to="/about">
                    Über uns
                  </Link>
                </li>
                <li className="lh-lg" aria-current="true">
                  <Link
                    className="text-decoration-none text-reset"
                    to="/whyindivit"
                  >
                    Warum indivit
                  </Link>
                </li>
                <li className="lh-lg" aria-current="true">
                  <Link
                    className="text-decoration-none text-reset"
                    to="/Sustainability"
                  >
                    Nachhaltigkeit
                  </Link>
                </li>
                <li className="lh-lg" aria-current="true">
                  <Link
                    className="text-decoration-none text-reset"
                    to="/delivery"
                  >
                    Lieferung
                  </Link>
                </li>
                <li className="lh-lg" aria-current="true">
                  <Link className="text-decoration-none text-reset" to="/hpp">
                    HPP Prozess
                  </Link>
                </li>
                <li className="lh-lg" aria-current="true">
                  <Link
                    className="text-decoration-none text-reset"
                    to="/smoothie-wissen"
                  >
                    Smoothie Wissen
                  </Link>
                </li>
                {/* <li className="lh-lg" aria-current="true">
                  <Link className="text-decoration-none text-reset" to="/story">
                    Story
                  </Link>
                </li> */}
              </ul>
            </div>
            {/* <!-- discover colum end--> */}
            {/* <!-- SOCIAL MEDIA colum--> */}
            <div className="col-12 custom-md-col pb-3">
              <h6 className="text-uppercase fw-bold">Folgen</h6>
              <ul className="list-group list-unstyled">
                <li className="lh-lg" aria-current="true">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none text-reset"
                    to="https://www.facebook.com/profile.php?id=100088113601684"
                  >
                    Facebook
                  </Link>
                </li>
                <li className="lh-lg" aria-current="true">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none text-reset"
                    to="https://www.instagram.com/indivit_smoothie"
                  >
                    Instagram
                  </Link>
                </li>
                <li className="lh-lg" aria-current="true">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none text-reset"
                    to="https://twitter.com/indivitsmoothie"
                  >
                    X (Twitter)
                  </Link>
                </li>
              </ul>
            </div>
            {/* <!-- SOCIAL MEDIA colum end--> */}
            {/* <!-- HELP & CONTACT colum--> */}
            <div className="col-12 custom-md-col pb-3">
              <h6 className="text-uppercase fw-bold">Hilfe und Kontakt</h6>
              <ul className="list-group list-unstyled">
                <li className="lh-lg" aria-current="true">
                  <Link className="text-decoration-none text-reset" to="/faqs">
                    FAQs
                  </Link>
                </li>
                <li className="lh-lg" aria-current="true">
                  <Link
                    className="text-decoration-none text-reset"
                    to="/contact"
                  >
                    Kontaktiere uns
                  </Link>
                </li>
                {/* <li className="lh-lg" aria-current="true">
                  <Link
                    className="text-decoration-none text-reset"
                    to="/terms-conditions"
                  >
                    Terms & Conditions
                  </Link>
                </li> */}
                {/* <li className="lh-lg" aria-current="true">
                  <Link
                    className="text-decoration-none text-reset"
                    to="/privacy-statement"
                  >
                    Privacy Policy
                  </Link>
                </li> */}
              </ul>
            </div>
            {/* <!-- HELP & CONTACT colum end--> */}
          </div>
        </div>
      </footer>
      <div className="container">
        <div className="row d-lg-flex justify-content-lg-between py-1">
          <div className="col-12 col-lg-auto order-lg-0 order-5 text-center text-lg-start">
            <small className="text-muted">© {settings?.footer_text}</small>
            {/* Smoothie Ninja GmbH, 2023 */}
          </div>
          <div className="col-6 col-lg-auto order-lg-1 order-0">
            <Link
              className="text-decoration-none text-muted"
              to="/terms-conditions"
            >
              <small>AGB</small>
            </Link>
          </div>
          <div className="col-6 col-lg-auto order-lg-2 order-1">
            <Link
              to="/privacy-statement"
              className="text-decoration-none text-muted"
            >
              <small>Datenschutzerklärung</small>
            </Link>
          </div>
          <div className="col-6 col-lg-auto order-lg-3 order-2">
            <Link
              to="/zahlungsmöglichkeiten"
              className="text-decoration-none text-muted"
            >
              <small>Zahlungsmöglichkeiten</small>
            </Link>
          </div>
          <div className="col-6 col-lg-auto order-lg-4 order-3">
            <Link
              to="/cancellation_policy"
              className="text-decoration-none text-muted"
            >
              <small>Widerrufsbelehrung</small>
            </Link>
          </div>
          <div className="col-6 col-lg-auto order-lg-5 order-4">
            <Link to="/imprint" className="text-decoration-none text-muted">
              <small>Impressum</small>
            </Link>
          </div>
        </div>
      </div>
      {/*  <!-- footer end --> */}
    </div>
  );
}
