import React from "react";
import logo from "../../assets/icon/logo1.png";
export default function Loader() {
  return (
    <div className="position-absolute start-0 top-0">
      <div className="w-100 h-100 loader">
        <img
          height="100px"
          width="100px"
          className="img-fluid rotate"
          src={logo}
        />
      </div>
    </div>
  );
}
