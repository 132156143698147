import React, { useLayoutEffect, useState, useEffect } from "react";
import rightIcon from "../../assets/img/left.png";
import leftIcon from "../../assets/img/right.png";
import discountImg from "../../assets/icon/discount.png";
import BoxUiBanner from "./BoxUiBanner";
import Carousel from "react-multi-carousel";
import { RecipeCard, SkeltonCard } from "../../components/Cards";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { getSmoothies } from "../../services/Smoothie";
import ViewBoxPopup from "./ViewBoxPopup";
import Loader from "../../components/common/Loader";
import {
  deleteBox,
  getBoxBenefits,
  getBoxById,
} from "../../services/SmoothieBox";
import { toast } from "react-toastify";
import ShareButtons from "../../components/common/ShareButtons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Heart } from "../../assets/icon/svgIcons";
import { IsInCart, IsWishlist } from "../../components/common/utils";
import { useDispatch, useSelector } from "react-redux";
import { addWishlistBox } from "../../services/Wishlist";
import { useCart } from "react-use-cart";
import { useForm } from "react-hook-form";
import ConfirmModal from "../../components/Modal/ConfirmModal";
import { useRef } from "react";
import useAddWishlist from "../../hooks/useAddWishlist";
import { getSubscriptionPlan, stripeCheckout } from "../../services/Orders";
import ConfirmWishModal from "../../components/Modal/ConfirmWishModal";
import ModalContainer from "../../components/Modal/ModalContainer";
import VATModal from "../../components/Modal/VATModal";
import CustomSmoothieDetailPopup from "../../components/CustomSmoothieDetailPopup";
import StarRating from "../../components/common/StarRating";
import {
  formatToGerman1,
  formatToGerman2,
} from "../../services/utils/germanFormat";
import { Helmet } from "react-helmet";
import ProductCarousel from "./ProductCarousel";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 700 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 700, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export default function BoxUi() {
  let params = useParams();
  const { pathname } = useLocation();
  // console.log("Location ", pathname);
  const queryClient = useQueryClient();
  const [modalVisible, setModalVisible] = useState(false);
  const { isLoading, isDone, addWishlist } = useAddWishlist(addWishlistBox);
  const { addItem } = useCart();
  const [selectedSize, setSelectedSize] = useState();
  const [selectedBoxData, setSelectedBoxData] = useState({});
  const [selectedQty, setSelectedQty] = useState(0);
  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [discount, setDiscount] = useState("");
  const [modalData, setModalData] = useState({});
  const closeRef = useRef();
  const commonImg = useSelector((state) => state.settings?.boxImg);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);
  const [subscriptionPlan, setSubscriptionPlan] = useState(false);
  const {
    isLoading: subscriptionLoading,
    error: subscriptionError,
    data: subscriptionRes,
  } = useQuery({
    queryKey: ["subscriptionPlan"],
    queryFn: getSubscriptionPlan,
  });
  let subscriptionData = subscriptionRes?.data?.data;
  const {
    isLoading: smoothieLoading,
    error: smoothieError,
    data: smoothieData,
  } = useQuery({
    queryKey: ["smoothieListing"],
    queryFn: getSmoothies,
  });
  const {
    isLoading: benefitsLoading,
    error: benefitsError,
    data: benefitsData,
  } = useQuery({
    queryKey: ["boxBenefits"],
    queryFn: getBoxBenefits,
  });

  // console.log("Box Benefits ", benefitsData);

  const {
    isLoading: boxLoading,
    error: boxError,
    data,
  } = useQuery({
    queryKey: ["boxById", params?.id],
    queryFn: () => getBoxById(params?.id),
  });
  // console.log("Box By ID ", data);

  useEffect(() => {
    if (data?.response?.status == 404) {
      toast.error("Keine Daten gefunden");
      navigate("/boxes");
    }
  }, [data]);
  let boxImage = data?.data?.data?.smoothie_image;
  // let page = searchParams.get("page");
  const boxData = data?.data?.data;
  const boxDescription = boxData?.smoothie_box_descriptions;
  const smoothies = smoothieData?.data?.smoothies;
  // console.log("Box Image ", boxImage);
  let tempPrice =
    !selectedBoxData?.price || selectedBoxData?.price == 0
      ? selectedBoxData?.smoothie_box_size?.price
      : selectedBoxData?.price;

  let perLitterPrice =
    ((!selectedBoxData?.price || selectedBoxData?.price == 0
      ? selectedBoxData?.smoothie_box_size?.price
      : selectedBoxData?.price) /
      selectedQty) *
    4;

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group position-absolute  w-100 ">
        <button
          className=" btn position-absolute start-0 px-0 shadow-none"
          onClick={() => previous()}
        >
          <img
            height="114px"
            width="62px"
            className="img-fluid "
            src={leftIcon}
          />
        </button>
        <button
          className="btn position-absolute end-0 px-0 shadow-none"
          onClick={() => next()}
        >
          <img
            height="114px"
            width="62px"
            className="img-fluid "
            src={rightIcon}
          />
        </button>
      </div>
    );
  };
  const handleCart = (data) => {
    // console.log("Box Data ", selectedBoxData, data);

    let tempPrice =
      !selectedBoxData?.price || selectedBoxData?.price == 0
        ? selectedBoxData?.smoothie_box_size?.price
        : selectedBoxData?.price;
    addItem({
      ...data,
      ...boxData,
      id: `${boxData?.id}${selectedSize}${subscriptionPlan}`,
      box_size: selectedSize,
      price: discount ? tempPrice - (discount / 100) * tempPrice : tempPrice,
      actualPrice: tempPrice,
      discounted: discount,
      boxSmoothies: selectedBoxData?.smoothie_box,
      short_detail: boxData?.smoothie_box_descriptions[0]?.short_detail,
      subscriptionPlan,
      subscription_id: subscription?.id,
      duration: subscription?.duration,
      subscriptionObj: subscription,
      perLitterPrice,
      image: boxImage[0]?.images,
    });
    toast.success("Deine Box liegt jetzt im Warenkorb");
    // Added to Cart Successfuly
  };

  const wishlist = useSelector((state) => state?.wishlist);
  useEffect(() => {
    if (selectedSize) {
      let filt = boxDescription?.find(
        (d, i) => selectedSize == d.smoothie_box_size_id
      );
      setSelectedBoxData(filt);
      let filtQty = boxDescription?.find(
        (d) => d.smoothie_box_size?.id == selectedSize
      )?.smoothie_box_size?.size;
      setSelectedQty(filtQty);
    }
  }, [selectedSize, boxDescription]);
  const handleDelete = (id) => {
    setLoading(true);
    deleteBox(id)
      .then((res) => {
        if (res?.status == 200) {
          closeRef.current.click();
          toast.success(res?.data);
          queryClient.invalidateQueries([
            "boxListing",
            "limitedboxListing",
            "boxById",
          ]);

          navigate("/boxes");
        } else {
          toast.error(res?.response?.data?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error ", err);
        toast.error(err?.data);
      });
  };
  useEffect(() => {
    if (boxDescription && boxDescription?.length > 0) {
      setSelectedSize(boxDescription[0]?.smoothie_box_size_id);
    }
  }, [boxDescription]);

  const handleSubscriptionId = (val) => {
    let objSubscription = subscriptionData?.find((d) => d.id == val);
    setSubscription(objSubscription);
    setDiscount(objSubscription?.discount);
  };
  const handleSubscriptionDiscount = (val, plan) => {
    if (!plan) {
      setSubscriptionPlan(false);
    }
    let objSubscription = subscriptionData?.find((d) => d.duration == val);
    // debugger;
    // setSubscription(objSubscription?.id);
    if (objSubscription && subscriptionPlan) {
      setDiscount(
        (!selectedBoxData?.price || selectedBoxData?.price == 0
          ? selectedBoxData?.smoothie_box_size?.price
          : selectedBoxData?.price) -
          (!selectedBoxData?.price || selectedBoxData?.price == 0
            ? selectedBoxData?.smoothie_box_size?.price
            : selectedBoxData?.price) *
            (objSubscription.discount / 100)
      );
    } else {
      setDiscount(null);
      // setSubscription(null);
      // setSubscriptionPlan(false);
    }
    // setDiscount(objSubscription?.discount);
  };

  const handleWishlist = (id) => {
    IsWishlist(1, boxData?.id, wishlist)
      ? setModalVisible(true)
      : addWishlist({ box_id: id });
  };
  useEffect(() => {
    if (isDone) {
      setModalVisible(false);
    }
  }, [isDone]);

  // console.log("Selected box Data ", selectedBoxData);
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
      </Helmet>
      {boxData && (
        <Helmet>
          {boxData?.name && <title> {`Indivit | ${boxData?.name}`} </title>}
          {selectedBoxData?.short_detail && (
            <meta
              name="description"
              content={`${selectedBoxData?.short_detail}`}
            />
          )}
        </Helmet>
      )}
      <ModalContainer
        isOpen={modalVisible}
        closeModal={() => setModalVisible(false)}
      >
        <ConfirmWishModal
          setModalVisible={setModalVisible}
          fun={() => addWishlist({ box_id: boxData?.unique_id })}
          isLoading={isLoading}
          innerHtml="Du entfernst das Element gerade von deinem Merkzettel"
          okLabel="Klingt gut"
        />
      </ModalContainer>
      {/* {<ConfirmWishModal />} */}
      {(loading || smoothieLoading || boxLoading) && <Loader />}
      {/* <!-- about section --> */}
      <ViewBoxPopup data={selectedBoxData} />
      {modalData && (
        <CustomSmoothieDetailPopup
          data={modalData}
          ingredients={modalData?.smoothie_ingredient}
        />
      )}
      <VATModal />
      <section id="flx-hero-boxui">
        <div className="container">
          <div className="row">
            <nav aria-label="breadcrumb" className="px-0">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/boxes">Boxes</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {boxLoading ? (
                    <span class=" placeholder-glow d-inline-flex py-1 ">
                      <span class="placeholder w-100px"></span>
                    </span>
                  ) : (
                    boxData?.name
                  )}
                </li>
              </ol>
            </nav>
          </div>
          <div className="row d-flex pt-0 pt-md-5">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              {/* <!-- <div className="text-left">
                        <img src="src/assets/img/about-image.png" className="img-fluid pe-0 pe-md-5" alt="">
                    </div> --> */}
              <div className="carousel-inner pe-0 pe-md-5 ps-0 ps-md-5">
                <ProductCarousel
                  boxImage={boxImage}
                  selectedBoxData={selectedBoxData}
                />
              </div>

              <div className="d-flex mt-2 mt-md-4 align-items-center">
                <img
                  className="img-fluid tw-w-10 xs:tw-w-16 sm:tw-w-20 lg:tw-w-24"
                  src={discountImg}
                />
                <div className="ms-2">
                  <h4 className="mb-0 sm:!tw-text-xl xs:!tw-text-lg !tw-text-base fw-bold">
                    Jetzt Smoothie-Abo abschließen
                  </h4>
                  <p className="tw-text-xs xs:tw-text-sm text-muted mb-0">
                    Abonnement kann jederzeit pausiert oder gekündigt werden
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <div className="text-left">
                <div className="d-flex align-items-center">
                  <span className="fs-2 fw-bold">
                    {boxLoading ? (
                      <span class=" placeholder-glow d-inline-flex py-1 ">
                        <span class="placeholder w-200px"></span>
                      </span>
                    ) : (
                      boxData?.name
                    )}
                  </span>
                  {selectedBoxData?.created_by == 1 && (
                    <span
                      className={`badge rounded-pill text-uppercase bg-info ms-2`}
                    >
                      Customized
                    </span>
                  )}
                </div>
                {boxData?.counts > 0 && (
                  <h6 className="d-flex">
                    <StarRating value={boxData?.ratings} />
                    &nbsp;
                    {formatToGerman1(boxData?.ratings)} / 5,0&nbsp;(
                    {boxData?.counts}&nbsp;Bewertungen)
                  </h6>
                )}

                {/* {selectedBoxData?.created_by == 0 && (
                )} */}
                {/* <button
                  className="btn btn-success"
                  onClick={() => dummyMutation.mutate()}
                >
                  Dummy Request
                </button> */}

                <div>
                  <button
                    className="btn btn-outline-success shadow-none rounded-pill mb-2"
                    onClick={() => handleWishlist(boxData?.unique_id)}
                  >
                    {/* Type  0 => Recipe, 1 => Box , 2=> Ingredient */}
                    {isLoading ? (
                      <span
                        class="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <span className="me-2">
                        <Heart filled={IsWishlist(1, boxData?.id, wishlist)} />
                      </span>
                    )}
                    {IsWishlist(1, boxData?.id, wishlist) ? "Auf" : "Auf"} den
                    Merkzettel
                  </button>
                </div>
                {selectedBoxData &&
                  parseInt(selectedBoxData?.box_status) !== 0 &&
                  selectedBoxData?.box_status != null && (
                    <div
                      className={`badge rounded-pill text-uppercase bg-danger mb-2`}
                    >
                      derzeit nicht verfügbar
                    </div>
                  )}
                {selectedSize && (
                  <>
                    <span className=" d-flex align-items-baseline">
                      <span
                        className={`fs-3 ${
                          discount && "text-decoration-line-through"
                        } `}
                      >
                        {formatToGerman2(tempPrice)}
                        &nbsp;€
                      </span>
                      {discount && (
                        <span className=" fs-3 ms-2">
                          {formatToGerman2(
                            tempPrice - (discount / 100) * tempPrice
                          )}
                          &nbsp;€
                        </span>
                      )}
                    </span>

                    <span className=" d-flex align-items-baseline text-muted mb-3">
                      <span
                        className={`${
                          discount && "text-decoration-line-through"
                        } `}
                      >
                        {formatToGerman2(perLitterPrice)}&nbsp;€
                      </span>
                      {discount && (
                        <span className="ms-2">
                          {formatToGerman2(
                            perLitterPrice - (discount / 100) * perLitterPrice
                          )}
                          &nbsp;€
                        </span>
                      )}
                      <span>
                        /Liter, inkl. MwSt, zzgl.&nbsp;
                        <span
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#VATModal"
                          data-bs-whatever="@getbootstrap"
                          className="text-decoration-underline"
                        >
                          Versand
                        </span>
                        &nbsp;+ Pfand
                      </span>
                    </span>
                  </>
                )}
                <select
                  className="form-select flx-selectbox-style bg-transparent"
                  placeholder="Select Box Size"
                  // {...register("box_size", {
                  //   required: true,
                  // })}
                  onChange={(e) => setSelectedSize(e.target.value)}
                >
                  <option value="">Wähle eine Größe aus</option>
                  {boxDescription
                    ?.sort(
                      (a, b) =>
                        parseInt(a?.smoothie_box_size?.size) -
                        parseInt(b?.smoothie_box_size?.size)
                    )
                    ?.map(({ smoothie_box_size }, index) => {
                      return (
                        <option
                          selected={index == 0 ? true : false}
                          key={index}
                          value={smoothie_box_size.id}
                        >
                          {smoothie_box_size.size} Smoothies (
                          {smoothie_box_size.variant})
                        </option>
                      );
                    })}
                </select>
                {errors?.box_size?.type === "required" && (
                  <p className="text-danger my-1">* Angabe notwendig</p>
                )}
                <h5 className="pt-4">Das steckt drin</h5>
                <div className="d-flex">
                  {selectedSize ? (
                    <button
                      className="flx-selectbox-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#viewBoxModal"
                      data-bs-whatever="@getbootstrap"
                    >
                      Box öffnen
                    </button>
                  ) : (
                    <button
                      onClick={handleSubmit()}
                      className="flx-selectbox-btn"
                    >
                      Box öffnen
                    </button>
                  )}

                  <Link
                    to={
                      selectedBoxData &&
                      parseInt(selectedBoxData?.box_status) !== 0 &&
                      selectedBoxData?.box_status !== null
                        ? ""
                        : selectedSize
                        ? `/custombox/${boxData?.unique_id}/${selectedBoxData?.smoothie_box_size?.size}`
                        : `/custombox/${boxData?.unique_id}`
                    }
                    className={`ms-2 btn flx-selectbox-btncustomize ${
                      selectedBoxData &&
                      parseInt(selectedBoxData?.box_status) !== 0 &&
                      selectedBoxData?.box_status !== null
                        ? "pe-disable"
                        : ""
                    }`}
                  >
                    Customize
                  </Link>
                  {/* Delete Button */}
                  {/* {boxData?.smoothie_box_descriptions[0]?.created_by == "1" && (
                    <ConfirmModal
                      className="p-8-20 rounded-2 fs-14 "
                      action={() => handleDelete(boxData?.unique_id)}
                      closeRef={closeRef}
                    />
                  )} */}
                </div>
                <h5 className="pt-4">Wähle eine passende Kaufoption</h5>
                <button
                  onClick={() => handleSubscriptionDiscount(null, false)}
                  className={` ${
                    subscriptionPlan
                      ? "flx-selectbox-btncustomize"
                      : "flx-selectbox-btn"
                  }`}
                >
                  Einmaliger Kauf
                </button>
                <button
                  onClick={() => setSubscriptionPlan(true)}
                  className={`ms-2 ${
                    subscriptionPlan
                      ? "flx-selectbox-btn"
                      : "flx-selectbox-btncustomize"
                  }`}
                >
                  Box als Abo
                </button>
                <form
                  className=""
                  // onSubmit={handleSubmit(handleCart)}
                >
                  {subscriptionPlan && (
                    <>
                      <select
                        className="form-select flx-selectbox-style bg-transparent mt-4"
                        {...register("subscription", {
                          required: true,
                          onChange: (e) => handleSubscriptionId(e.target.value),
                        })}
                      >
                        <option value="">Lieferintervall</option>
                        {subscriptionData?.map((d, i) => {
                          return (
                            <option key={i} value={d?.id}>
                              {d?.name}
                            </option>
                          );
                        })}
                      </select>
                      {errors?.subscription?.type === "required" && (
                        <p className="text-danger my-1">* Angabe notwendig</p>
                      )}
                    </>
                  )}
                  <div className="d-flex mt-4 ">
                    <button
                      disabled={
                        selectedBoxData &&
                        parseInt(selectedBoxData?.box_status) !== 0 &&
                        selectedBoxData?.box_status !== null
                      }
                      onClick={handleSubmit(handleCart)}
                      className={`flx-selectbox-style bg-theme-success text-white border-0 fw-bold ${
                        selectedBoxData &&
                        parseInt(selectedBoxData?.box_status) !== 0 &&
                        selectedBoxData?.box_status !== null
                          ? "pe-disable"
                          : ""
                      }`}
                      title="derzeit nicht verfügbar"
                    >
                      {IsInCart(boxData?.unique_id) && (
                        <span className="btn-label">
                          <i className="fa fa-check"></i>&nbsp;
                        </span>
                      )}
                      In den Warenkorb legen
                    </button>
                  </div>
                </form>
                <ShareButtons />
              </div>
            </div>
          </div>
          <div className="my-4"></div>
          <h5> {selectedBoxData?.short_detail} </h5>
          <div
            dangerouslySetInnerHTML={{ __html: selectedBoxData?.detail }}
          ></div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#FE6703"
            fillOpacity="1"
            d="M0,224L60,224C120,224,240,224,360,197.3C480,171,600,117,720,112C840,107,960,149,1080,165.3C1200,181,1320,171,1380,165.3L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
      </section>
      {/* <!-- about section --> */}

      {/* Banner Section Start */}
      <BoxUiBanner data={benefitsData?.data?.data} heading={true} />
      {/* Banner Section END */}

      {/* <!----Last Text section-----> */}
      <section id="flx-vue-slider">
        <div className="pt-5">
          <div className="container-fluid px-0">
            <div className="flx-vue-width">
              <h2
                className="text-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {/* thant's in it */}
                Das steckt drin
              </h2>
              <p
                className="text-center pb-5"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                Das sind die ausgewählten Smoothies in dieser Box. Du kannst den
                Boxinhalt auch anpassen, indem du oben auf “Customize” klickst.
              </p>
            </div>

            <div className=" m-auto position-relative ">
              <Carousel
                responsive={responsive}
                itemclassName="px-5"
                autoPlay={true}
                arrows={false}
                renderButtonGroupOutside={true}
                customButtonGroup={<ButtonGroup />}
                infinite={true}
              >
                {selectedBoxData && selectedBoxData?.smoothie_box?.length > 0
                  ? selectedBoxData?.smoothie_box?.map(
                      ({ smoothie }, index) => {
                        return (
                          <div
                            type="button"
                            onClick={() => setModalData(smoothie)}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            data-bs-whatever="@getbootstrap"
                          >
                            <RecipeCard
                              key={index}
                              isButton={false}
                              data={smoothie}
                            />
                          </div>
                        );
                      }
                    )
                  : Array.from(Array(3)).map((d) => {
                      return (
                        <div className="px-md-5">
                          <SkeltonCard />
                        </div>
                      );
                    })}
              </Carousel>
            </div>
          </div>
          <div className="text-center container">
            <hr />
            {/* <p>
              Still can't find the perfect recipe? Try our recipe finder to find
              the best tastiest recipes using only the Z
            </p> */}
          </div>
        </div>
      </section>
      {/* <!--Last Text section end------> */}
    </div>
  );
}
