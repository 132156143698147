import React, { useLayoutEffect } from "react";
import LoginCard from "./LoginCard";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

export default function Login() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
       <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
      </Helmet>
      <section id="flx-login">
        <LoginCard title="Wilkommen" redirect={true} />
        {/* Welcome */}
      </section>
    </div>
  );
}
