import { GET, POST } from "./Adapter/base";

export const getSmoothies = () => {
  return GET(`get_smoothie`);
};
export const getLimitedSmoothies = () => {
  return GET(`get_smoothie_limit`);
  // get Admin Smoothies Limit
};
export const getCustomSmoothies = () => {
  return GET(`get_smoothies`); //Listing For Customization Box
};
export const getSmoothieById = (id) => {
  return GET(`r/${id}`);
};
export const addCustomSmoothie = (data) => {
  return POST(`smoothies`, data);
};
export const getSmoothieCategory = () => {
  return GET(`get_smoothie_category`);
};
export const deleteSmoothie = (id) => {
  return POST(`del_smoothie/${id}`);
};
export const smoothieFeedback = (data) => {
  return POST(`feedbackComment`, data);
};
export const smoothieBenefits = () => {
  return GET(`benefits`);
};
