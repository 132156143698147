import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
import { getBoxSize } from "../../services/SmoothieBox";
import dlvIcon from "../../assets/icon/deliveryIcon.png";
import depositIcon from "../../assets/icon/deposit.png";
import { formatToGerman2 } from "../../services/utils/germanFormat";
import { formToJSON } from "axios";
export default function CartSidebar({ data }) {
  const { cartTotal } = useCart();
  const {
    isLoading: boxSizeLoading,
    error: boxSizeError,
    data: boxSizeData,
  } = useQuery({
    queryKey: ["getBoxSize"],
    queryFn: getBoxSize,
  });
  let boxSize = boxSizeData?.data?.data;

  const { updateCartMetadata } = useCart();
  let deposit = 0;
  let qty = 0;
  data?.map((d) => {
    qty +=
      boxSize?.find((bs) => bs?.id == parseInt(d.box_size))?.size * d?.quantity;

    // debugger;
  });
  deposit = qty * 0.25;
  useEffect(() => {
    if (deposit) {
      updateCartMetadata({
        deposit: deposit,
        qty: qty,
      });
    }
  }, [deposit]);

  return (
    <div>
      <div className=" bg-white shadow rounded-3 p-4">
        <div className="d-flex justify-content-between pb-3">
          <h6>Zwischensumme</h6>
          <p>{formatToGerman2(cartTotal)}&nbsp;€</p>
        </div>

        {data?.map((item, index) => {
          return (
            <div key={index}>
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  {item?.name} x {item?.quantity}
                </p>
                <p className="mb-0">
                  {formatToGerman2(item?.itemTotal)}&nbsp;€
                </p>
              </div>
              <p className="text-muted fs-12 text-italic">
                <i>
                  Preis {formatToGerman2(item?.perLitterPrice)}&nbsp;€/Liter
                </i>
                {/* Price */}
              </p>
            </div>
          );
        })}

        <div className="d-flex justify-content-between">
          <p>
            <img className="img-fluid me-2" src={dlvIcon} width="25" />
            <span>Versandkosten</span>
            {/* Delivery Charges */}
          </p>
          <p>Kal. im nächsten Schritt</p>
        </div>
        <div className="d-flex justify-content-between">
          <p>
            <img className="img-fluid me-2" src={depositIcon} width="25" />
            Pfand
          </p>
          <p>{formatToGerman2(deposit)} €</p>
        </div>
        <hr />
        <div className="d-flex justify-content-between pt-3">
          <p>Summe inkl. MwSt.</p>
          <p>
            {formatToGerman2(parseFloat(cartTotal) + parseFloat(deposit))}
            &nbsp;€
          </p>
        </div>
        <hr />

        <div className=" d-flex justify-content-center">
          <Link
            to="/checkout"
            className="btn btn-primary btn-solid-success w-75 mt-3"
          >
            Weiter zur Kasse
          </Link>
          {/* Proceed to Checkout */}
        </div>
      </div>
    </div>
  );
}
