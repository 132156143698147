import React from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";

export default function NotFound() {
  const { pathname } = useLocation();

  return (
    <section id="flx-faqs-section">
       <Helmet>
        <link rel="canonical" href={`https://indivit.de${pathname}`} />
      </Helmet>
      <div className="container pt-5">
        <div className="flx-hero-faq text-center">
          <p className="text-center fs-3">oh-no</p>
          <h1 className="text-center pb-2">On the Wrong Way</h1>
          <Link className="btn btn-solid-success" to="/">
            Back to Shopping
          </Link>
        </div>
      </div>
    </section>
  );
}
